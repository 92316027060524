import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Button, Header, Input, Message } from "semantic-ui-react";
import { integration } from "../../../api_generated";
import { useAuth0TokenOptions } from "../../../data";
import { SEMANTIC_GREY } from "../../../utils/colors";
import { getErrorMessage } from "../../../utils/errors";
import { deleteIntegrationToken, setIntegrationToken } from "../../data";
import { useIntegrationTokenQuery } from "../../query";

const ThirdPartyIntegrationToken = (props: { integration: integration; displayName: string }) => {
    const { integration, displayName } = props;

    // Local state:
    const [isRevealed, setIsRevealed] = useState(false);
    const [token, setToken] = useState(""); // replace with your token management

    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    // Query state:
    const { data: tokenData, isLoading: isTokenLoading, error: tokenError } = useIntegrationTokenQuery(integration);

    useEffect(() => {
        if (!isTokenLoading && !tokenError && tokenData !== undefined && tokenData.token !== undefined) {
            setToken(tokenData.token);
        }
    }, [tokenData]);

    const handleReveal = () => setIsRevealed((isRevealed) => !isRevealed);

    const { mutateAsync: handleSetToken, error: setTokenError } = useMutation({
        mutationFn: () => setIntegrationToken(integration, token, auth0TokenOptions),
        onSuccess: () => {
            setToken(token);
        },
    });

    const { mutateAsync: handleDeleteToken, error: deleteTokenError } = useMutation({
        mutationFn: () => deleteIntegrationToken(integration, auth0TokenOptions),
        onSuccess: () => {
            setToken("");
        },
    });

    const errorMessage = getErrorMessage(setTokenError) || getErrorMessage(deleteTokenError);

    return (
        <>
            <div style={{ display: "block" }}>
                <Header as="h2" size="medium" style={{ marginBottom: 0, marginTop: `${24 / 14}rem` }}>
                    {displayName} API Token
                </Header>
                <span
                    style={{
                        color: SEMANTIC_GREY,
                        paddingBottom: `${10 / 14}rem`,
                        display: "block",
                        fontSize: "0.9em",
                    }}
                >
                    Enter your {displayName} API token here to track fine-tuning job metrics in your {displayName}{" "}
                    account.
                </span>
            </div>
            {errorMessage && (
                <div style={{ display: "block", marginBottom: `${24 / 14}rem` }}>
                    <Message error>
                        <p>{errorMessage}</p>
                    </Message>
                </div>
            )}
            <div style={{ display: "flex", alignItems: "center", gap: `${10 / 14}rem`, marginBottom: `${24 / 14}rem` }}>
                <Input
                    type={isRevealed ? "text" : "password"}
                    value={token}
                    onChange={(e) => setToken(e.target.value)}
                    placeholder="API Token"
                    style={{ flex: 0.2 }}
                />
                <button
                    onClick={handleReveal}
                    style={{ background: "none", border: "none", cursor: "pointer" }}
                    aria-label={`${isRevealed ? "Hide" : "Show"} Token`}
                >
                    <FontAwesomeIcon icon={faEye} size="lg" />
                </button>
                <Button onClick={() => handleSetToken()}>Save Token</Button>
                <Button onClick={() => handleDeleteToken()} negative>
                    Delete Token
                </Button>
            </div>
        </>
    );
};

export default ThirdPartyIntegrationToken;
