import { getTraceId } from "../../api/trace";
import { Auth0TokenOptions } from "../../data";
import metrics from "../../metrics/metrics";
import { createV1APIServer, redirectIfSessionInvalid } from "../../utils/api";
import { getErrorMessage } from "../../utils/errors";

// TODO: Ideally should be autogenerated:
export const AWS_REGIONS = ["us-west-2", "us-east-1"] as const;
export const AZURE_REGIONS = ["West US 2"] as const;
export type AWSRegionsType = (typeof AWS_REGIONS)[number];
export type AzureRegionsType = (typeof AZURE_REGIONS)[number];

// TODO: Add gcp when supported:
type EnvironmentInitializationParamsType<T> = T extends "aws"
    ? { cloud: T; cloudRegion: AWSRegionsType; cloudCredentialsAWS: AWSCredentials }
    : { cloud: "azure"; cloudRegion: AzureRegionsType; cloudCredentialsAzure: AzureCredentials };

// TODO: Should be autogenerated:
export interface AzureCredentials {
    appID: string;
    password: string;
    tenantID: string;
    subscriptionID: string;
    resourceGroup: string;
}
export interface AWSCredentials {
    roleArn: string;
}

export const initializeEnvironment = async <T>(
    envShortCode: string,
    cloudConfig: EnvironmentInitializationParamsType<T>,
    auth0TokenOptions?: Auth0TokenOptions,
) => {
    const endpoint = `environments/${envShortCode}`;
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .patch(endpoint, {
            ...cloudConfig,
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "PATCH",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const provisionEnvironment = async (shortCode: string, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `environments/${shortCode}/provision`;
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer.post(endpoint).catch((error) => {
        const errorMsg = getErrorMessage(error) ?? "";
        metrics.captureError("api_error", errorMsg, {
            method: "POST",
            endpoint,
            trace_id: getTraceId(error),
        });
        redirectIfSessionInvalid(errorMsg);
        throw errorMsg;
    });
};
