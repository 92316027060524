// https://semantic-ui.com/usage/theming.html

export const SEMANTIC_RED = "#db2828";
export const SEMANTIC_RED_ACTIVE = "#B21E1E";
export const SEMANTIC_RED_LIGHT = "#9f3a38";
export const SEMANTIC_ORANGE = "#f2711c";
export const SEMANTIC_YELLOW = "#fbbd08";
export const SEMANTIC_DARK_YELLOW = "#573A08";
export const SEMANTIC_OLIVE = "#b5cc18";
export const SEMANTIC_OLIVE_ACTIVE = "#8D9E13";
export const SEMANTIC_GREEN = "#21ba45";
export const SEMANTIC_GREEN_ACTIVE = "#198F35";
export const SEMANTIC_TEAL = "#00b5ad";
export const SEMANTIC_BLUE = "#2185d0";
export const SEMANTIC_BLUE_ACTIVE = "#1A69A4";
export const SEMANTIC_VIOLET = "#6435c9";
export const SEMANTIC_PURPLE = "#a333c8";
export const SEMANTIC_PINK_ACTIVE = "#CA4675";
export const SEMANTIC_PINK = "#e03997";
export const SEMANTIC_BROWN = "#a5673f";
export const SEMANTIC_GREY = "#767676";
export const SEMANTIC_GREY_ACTIVE = "#767676";
export const SEMANTIC_GREY_DISABLED = "#656565";
export const SEMANTIC_BLACK = "#1b1c1d";
export const SEMANTIC_WHITE = "#fff";

export const PREDIBASE_LIGHT_GRAY = "#F7F7F7";
export const PREDIBASE_ORANGE = "#E25636";
