import React, { forwardRef, useState } from "react";

import { Button, Form, Message } from "semantic-ui-react";

import { UiNode, UiText } from "@ory/kratos-client";
import { AxiosError } from "axios";
import { Link } from "react-router-dom";
import metrics from "../../../metrics/metrics";
import { FORM_TYPE_PASSWORD } from "../constants";
import { KratosErrorMessage, renderFormFields, sortFormFields, sortOIDCFormFields } from "./KratosFormUtils";

const KratosSigninForm = forwardRef(
    (
        props: {
            action: string;
            messages?: UiText[];
            fields: UiNode[];
            submitLabel: string;
            onSubmit: (e: React.FormEvent<HTMLFormElement>) => void | Promise<void | AxiosError<unknown, any>>;
        },
        buttonRef: React.Ref<Button>,
    ) => {
        const [submitting, setSubmitting] = useState<boolean>(false);
        const [formError, setFormError] = useState<string>("");
        const fieldsSorted = sortFormFields(props.fields);
        const oidcFields = sortOIDCFormFields(props.fields);

        const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
            setSubmitting(true);
            props
                .onSubmit(e)
                ?.catch((error) => {
                    setFormError(error?.message || "Looks like something went wrong. Try again later.");
                })
                ?.finally(() => setSubmitting(false));
        };

        const submitType = "Login";
        return (
            <>
                <KratosErrorMessage messages={props.messages} />
                {formError && <Message error>{formError}</Message>}
                {props.action && (
                    <React.Fragment>
                        <Form onSubmit={onFormSubmit}>
                            {renderFormFields(fieldsSorted)}
                            <Link to="/recovery" style={{ float: "left", marginBottom: "1rem" }}>
                                Forgot password?
                            </Link>
                            <Button
                                ref={buttonRef}
                                loading={submitting}
                                disabled={submitting}
                                className={metrics.BLOCK_AUTO_CAPTURE}
                                onClick={() => metrics.captureClick("Auth." + submitType)}
                                color="black"
                                fluid
                                size="large"
                                name="method"
                                type="submit"
                                value={FORM_TYPE_PASSWORD}
                            >
                                {props.submitLabel}
                            </Button>
                        </Form>
                    </React.Fragment>
                )}
            </>
        );
    },
);

export default KratosSigninForm;
