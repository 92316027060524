import React from "react";
import { Divider, Icon, Label, Popup } from "semantic-ui-react";

export const defaultValueMessage = (defaultValue: any) => {
    return "Default: " + defaultValue;
};

export const getTooltip = (
    metricName: string,
    name: string,
    info: React.ReactNode,
    defaultValue?: CreateModelValue,
) => {
    let defaultMsg = defaultValueMessage(defaultValue);
    const defaultLabel = <Label size="tiny">{defaultMsg}</Label>;
    const data = info ? (
        <span>
            {info}
            <Divider />
            {defaultLabel}
        </span>
    ) : (
        defaultLabel
    );

    return (
        <Popup
            className="transition-scale"
            hoverable
            wide={"very"}
            position={"right center"}
            trigger={<Icon name={"question circle"} color={"blue"} />}
            content={data}
        />
    );
};
