import { useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosInstance } from "axios";
import JSZip from "jszip";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Button, Form, Icon, List, Message, Modal } from "semantic-ui-react";
import { useAuth0TokenOptions } from "../../data";
import ConnectionSelector from "../../data/ConnectionSelector";
import DatasetSelector from "../../data/DatasetSelector";
import metrics from "../../metrics/metrics";
import { createV1APIServer, redirectIfSessionInvalid } from "../../utils/api";
import { getErrorMessage } from "../../utils/errors";
import { getFileTypeAndExtension } from "../../utils/files";
import { GET_MODEL_REPO_QUERY_KEY } from "../query";

const MODEL_WEIGHTS = "model_weights";

/**
 * A simple function to strip out "models/" and "model_weights/" from the start of a string.
 * @param filePath the relative filepath in the zip file
 * @returns The filename of the file in the zip file
 */
const getFileName = (filePath: string) => filePath.split("/").pop();

/**
 * A function to validate the zip file uploaded by the user and fetch the required files.
 * @param file a file object containing a zip file
 * @returns A zip file with the metadata files required to register a model and a list of model weights files to upload.
 */
export const validateModelZipFile = async (uploadedZip: JSZip) => {
    const modelMetadataZip = new JSZip(); // JSZip instance for creating model_metadata.zip for registering model
    const requiredFiles = ["model_hyperparameters.json", "training_progress.json", "training_set_metadata.json"];

    // Sometimes users are going to upload a zip file with a folder called "model"
    // If that folder is present, we need to look at files in that subdirectory:
    const files = uploadedZip.files;
    const folderPrefix = _.get(files, "model/") ? "model/" : "";
    for (const requiredFile of requiredFiles) {
        const requiredFilePath = folderPrefix + requiredFile;
        if (!_.has(files, requiredFilePath)) {
            throw new Error(`Failed to upload model: ${requiredFilePath} is missing from zip file`);
        }

        modelMetadataZip.file(requiredFile, files[requiredFilePath]?.async("blob"));
    }

    // Check model_weights file exists in zip file
    const modelWeightsFilePath = folderPrefix + MODEL_WEIGHTS;
    const modelWeightsFiles = [];
    if (_.get(files, modelWeightsFilePath)) {
        modelWeightsFiles.push({
            fileName: getFileName(modelWeightsFilePath),
            file: files[modelWeightsFilePath]?.async("blob"),
        });
    } else {
        uploadedZip.forEach((relativePath) => {
            if (
                relativePath.startsWith(modelWeightsFilePath) &&
                !relativePath.includes("README") &&
                !relativePath.endsWith("/")
            ) {
                modelWeightsFiles.push({
                    fileName: getFileName(relativePath),
                    file: files[relativePath]?.async("blob"),
                });
            }
        });
    }

    if (modelWeightsFiles.length === 0) {
        throw new Error("Failed to upload model: model_weights is missing from zip file");
    }

    return {
        modelMetadataZip,
        modelWeightsFiles,
    };
};

const uploadModel = async (
    apiServer: AxiosInstance | null,
    modelRepo: ModelRepo,
    onUpload: () => void,
    setOpen: (open: boolean) => void,
    setLoading: (loading: boolean) => void,
    setErrorMessage: (errorMessage: string | null) => void,
    file?: File,
    dataset?: Dataset,
) => {
    // Check file is selected - error if not
    if (!file) {
        throw new Error("Please select a file to upload");
    }
    if (apiServer === null) {
        throw new Error("Failed to upload model - unknown error");
    }

    setLoading(true);

    try {
        /**
         * Step 1: Validate the file is a zip file and contains the required files.
         */
        const uploadedZip = await new JSZip().loadAsync(file);
        let modelMetadataZip: JSZip;
        let modelWeightsFiles: { fileName: string | undefined; file: Promise<Blob> }[];

        try {
            const results = await validateModelZipFile(uploadedZip);
            modelMetadataZip = results.modelMetadataZip;
            modelWeightsFiles = results.modelWeightsFiles;
        } catch (error) {
            setErrorMessage((error as Error).message);
            setLoading(false);
            return;
        }

        /**
         * Step 2: Upload the model metadata files to the server to create a new model.
         */

        // Create zip of smaller model metadata files.
        const modelRegistrationFile = new File(
            [await modelMetadataZip.generateAsync({ type: "blob" })],
            "model_metadata.zip",
        );

        // Generate form data to send in request to register uploaded model request
        const formData = new FormData();
        formData.append("file", modelRegistrationFile);
        formData.append("fileName", modelRegistrationFile.name);
        formData.append("datasetID", String(dataset?.id));
        formData.append("repoID", String(modelRepo.id));
        formData.append(
            "modelWeightsFiles",
            modelWeightsFiles.map((modelWeightFile) => modelWeightFile.fileName).join(","),
        );

        // Upload the smaller metadata files. This will register the model record in the DB and return a
        // presigned URL where the model weights file can be directly uploaded to the object store.
        const registerResp = await apiServer.post("/models/register_uploaded_model", formData, {
            headers: {
                "content-type": "multipart/form-data",
            },
        });

        if (registerResp.data.errorMessage) {
            setErrorMessage(registerResp.data.errorMessage);
            setLoading(false);
            return;
        }

        /**
         * Step 3: Upload the model weights file(s) to the object store.
         */
        const model = registerResp.data.model;
        const presignedURLs = registerResp.data.modelWeightsPresignedFiles;

        for (const [index, modelWeightFile] of modelWeightsFiles.entries()) {
            const presignedURL = presignedURLs[index];
            const uploadUrl = presignedURL.url;

            const requiredHeaders = new Map<string, string>(Object.entries(presignedURL.headers || {}));
            const contentType = modelWeightFile.fileName?.endsWith("json") ? "application/json" : "binary/octet-stream";
            let headers: Record<string, string> = { "Content-Type": contentType };
            Object.entries(requiredHeaders).forEach((entry) => {
                const [header, val] = entry;
                headers[header] = val;
            });

            const uploadWeightsResp = await fetch(uploadUrl, {
                method: "PUT",
                body: await modelWeightFile.file,
                headers: headers,
            });

            if (!uploadWeightsResp.ok) {
                setErrorMessage(
                    `Failed to upload model weights - ${uploadWeightsResp.status} ${uploadWeightsResp.statusText} - ${uploadWeightsResp.type} - ${modelWeightFile.fileName}`,
                );
                setLoading(false);
                return;
            }
        }

        const completeUploadResp = await apiServer.post("models/complete_upload_model/" + model.id);
        if (completeUploadResp.data.error) {
            setErrorMessage(getErrorMessage(completeUploadResp.data.error));
            setLoading(false);
            return;
        }

        setOpen(false);
        setLoading(false);
        onUpload();
    } catch (error) {
        let errorMsg: string | null = "Failed to upload model - unknown error";
        if (error instanceof AxiosError) {
            errorMsg = getErrorMessage(error);
        }

        redirectIfSessionInvalid(errorMsg ?? "");
        setErrorMessage(errorMsg);
        setLoading(false);
    }
};

const UploadModelModal = (props: { modelRepo: ModelRepo }) => {
    const { modelRepo } = props;

    const [connection, setConnection] = useState<Connection>();
    const [dataset, setDataset] = useState<Dataset>();

    const [file, setFile] = useState<File>();
    const [fileName, setFileName] = useState<string>();

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    // Query state:
    const queryClient = useQueryClient();
    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    const [apiServer, setAPIServer] = useState<AxiosInstance | null>(null);
    useEffect(() => {
        const getAPIServer = async () => {
            const v1APIServer = await createV1APIServer(auth0TokenOptions);
            // NOTE: Whoever wrote the axios typings is a moron because the return type of axios.create is not
            // AxiosInstance -- it's a wrap function. And React will see that and treat it as a callback that
            // setState should directly call. FML.
            // See: [1], [2]:
            // [1]: https://github.com/axios/axios/issues/4365
            // [2]: https://stackoverflow.com/questions/64427195/calling-setstate-will-execute-the-function-value-instead-of-passing-it
            setAPIServer(() => v1APIServer);
        };
        getAPIServer();
    }, []);

    useEffect(() => {
        setDataset(undefined);
        setFile(undefined);
    }, [open]);

    const onSelectConnection = (connection: Connection) => {
        setConnection(connection);
        setDataset(undefined);
    };
    const selectDataset = (dataset: Dataset) => setDataset(dataset);

    const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const file = e.target.files?.[0];

        setErrorMessage("");

        if (!file) {
            return;
        }

        const { name, type, size } = file;
        const { fileType, fileExt } = getFileTypeAndExtension(name, type);

        // Check file type and extension exist and are zip files
        if (fileType !== "zip" && fileExt !== "zip") {
            setErrorMessage("File type must be a zip file containing a ludwig model.");
            return;
        }

        // Check file size is less than 2GB
        if (size > 2000000000) {
            setErrorMessage("File size must be less than 2GB.");
            return;
        }

        setFile(file);
        setFileName(name);
    };

    const uploadModelHelpMsg = () => {
        const lst = (
            <List>
                <List.Item>- model files (like model_hyperparameters.json)</List.Item>
                <List.Item>- description.json</List.Item>
                <List.Item>- training_statistics.json</List.Item>
            </List>
        );
        return (
            <Message
                color={"blue"}
                content={
                    <>
                        <span>Upload a zip file containing:</span> {lst}
                    </>
                }
            />
        );
    };

    return (
        <Modal
            className={metrics.BLOCK_AUTO_CAPTURE}
            onClose={() => {
                setOpen(false);
            }}
            onOpen={() => {
                setOpen(true);
            }}
            open={open}
            trigger={
                <Button className={metrics.BLOCK_AUTO_CAPTURE} size="tiny">
                    <Icon name="upload" />
                    Upload Ludwig Model
                </Button>
            }
        >
            <Modal.Header>Upload Ludwig Model</Modal.Header>
            <Modal.Content>
                {uploadModelHelpMsg()}
                <Form>
                    <Form.Group widths="equal">
                        <ConnectionSelector selectedConnection={connection} onSelectConnection={onSelectConnection} />
                        <DatasetSelector connection={connection} dataset={dataset} selectDataset={selectDataset} />
                    </Form.Group>
                </Form>
                <Form>
                    <Form.Field width={3}>
                        <label>File upload</label>
                        <Button
                            content="Choose File"
                            as="label"
                            htmlFor="file"
                            type="button"
                            labelPosition="left"
                            icon="file"
                        />
                        <input type="file" id="file" hidden onChange={onFileChange} />
                        <label>{fileName}</label>
                    </Form.Field>
                </Form>
                {errorMessage !== null && (
                    <Message negative>
                        <Message.Header>Error in model upload</Message.Header>
                        <p>{errorMessage}</p>
                    </Message>
                )}
            </Modal.Content>
            <Modal.Actions>
                <Button color="black" onClick={() => setOpen(false)}>
                    Cancel
                </Button>
                <Button
                    content="Upload"
                    labelPosition="right"
                    icon="checkmark"
                    onClick={() =>
                        uploadModel(
                            apiServer,
                            modelRepo,
                            () => {
                                queryClient.invalidateQueries({ queryKey: GET_MODEL_REPO_QUERY_KEY(modelRepo.id) });
                            },
                            setOpen,
                            setLoading,
                            setErrorMessage,
                            file,
                            dataset,
                        )
                    }
                    loading={loading}
                    disabled={fileName === undefined || !dataset}
                    positive
                />
            </Modal.Actions>
        </Modal>
    );
};

export default UploadModelModal;
