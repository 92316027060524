import { Loader, Message } from "semantic-ui-react";
import { MetronomeDashboardType } from "../../../types/billing";
import { commonGetMetronomeDashboardQuerySettings, useMetronomeDashboardQuery } from "../../query";

const UsageTab = () => {
    const {
        data: url,
        isLoading,
        error,
    } = useMetronomeDashboardQuery(MetronomeDashboardType.USAGE, commonGetMetronomeDashboardQuerySettings());

    if (error) {
        return (
            <Message negative>
                <Message.Header>Failed to load usage</Message.Header>
                <p>{error.message}</p>
            </Message>
        );
    }

    return (
        <div
            style={{
                maxWidth: `${1024 / 14}rem`,
                margin: "0 auto",
            }}
        >
            {isLoading ? (
                <Loader active inline="centered" />
            ) : (
                <iframe
                    src={url}
                    width="100%"
                    height="1800px"
                    title="Usage Dashboard"
                    seamless
                    style={{
                        border: "none",
                    }}
                />
            )}
        </div>
    );
};

export default UsageTab;
