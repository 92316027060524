import { useContext, useState } from "react";
import { Link, useMatch, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
    Breadcrumb,
    Button,
    Divider,
    Dropdown,
    Header,
    Icon,
    Loader,
    Menu,
    Message,
    Popup,
    Tab,
} from "semantic-ui-react";
import { deployment, deploymentStatus, deploymentType, role, tier } from "../../../api_generated";
import { BadLink } from "../../../components/BadLink/BadLink";
import { useUserRole } from "../../../settings/query";
import { USER_STATE } from "../../../state/global";
import { SEMANTIC_BLUE, SEMANTIC_GREY, SEMANTIC_GREY_DISABLED, SEMANTIC_WHITE } from "../../../utils/colors";
import { getErrorMessage } from "../../../utils/errors";
import { FeatureFlagsContext, getPosthogFeatureFlag, POSTHOG_FEATURE_FLAG } from "../../../utils/feature-flags";
import { isKratosUserContext } from "../../../utils/kratos";
import { centsToDollars } from "../../../utils/strings";
import { useDeploymentQuery } from "../../data/query";
import { useDeploymentEventUpdates } from "../../data/websocket";
import DeploymentStatusChip from "../../misc/DeploymentStatusChip";
import GuaranteedDeploymentChip from "../../misc/GuaranteedDeploymentChip";
import { isDeploymentPromptable } from "../../misc/utils";
import CreateUpdateDeploymentModal from "../modals/CreateUpdateDeploymentModal";
import PromptInstructionsModal from "../modals/PromptInstructionsModal";
import DeploymentConfigTab from "./tabs/DeploymentConfigTab";
import DeploymentEventsTab from "./tabs/DeploymentEventsTab";
import DeploymentHealthTab from "./tabs/DeploymentHealthTab";
import DeploymentLogsTab from "./tabs/DeploymentLogsTab";

const Breadcrumbs = (props: { deployment: deployment }) => {
    return (
        <div className={"version-header"} style={{ marginLeft: "20px" }}>
            <Breadcrumb>
                <Breadcrumb.Section>
                    <Link to="/deployments">Deployments</Link>
                </Breadcrumb.Section>
                <Breadcrumb.Divider />
                <Breadcrumb.Section active style={{ fontWeight: "normal" }}>
                    {props.deployment.name}
                </Breadcrumb.Section>
            </Breadcrumb>
        </div>
    );
};

const DeploymentHeader = (props: { children?: React.ReactNode; deployment?: deployment }) => {
    // Recoil state:
    const [userContext] = useRecoilState(USER_STATE);

    // Local state:
    const { children, deployment } = props;

    // Context state:
    const { posthogFeatureFlags } = useContext(FeatureFlagsContext);
    const showReplicasCount = getPosthogFeatureFlag(POSTHOG_FEATURE_FLAG.ShowReplicasCount, posthogFeatureFlags);

    // Derived user state:
    let userTier: tier | undefined;
    if (userContext) {
        const isKratosContext = isKratosUserContext(userContext);
        userTier = isKratosContext ? userContext.tenant.subscription.tier : userContext?.tenant.tier;
    }

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                    style={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start", gap: "0.57rem" }}
                >
                    <Header as="h2" style={{ margin: 0 }}>
                        {deployment?.name}
                    </Header>
                    {deployment?.usesGuaranteedCapacity && <GuaranteedDeploymentChip />}
                    {deployment?.status && deployment?.status.length > 0 && (
                        <span>
                            <DeploymentStatusChip status={deployment.status} />
                        </span>
                    )}
                </div>
                <div style={{ display: "flex" }}>{children}</div>
            </div>
            <div style={{ display: "flex", padding: 0, color: SEMANTIC_GREY, marginBottom: `${24 / 14}rem` }}>
                {deployment?.type === deploymentType.DEDICATED && (
                    <span style={{ marginRight: "1.14rem" }}>Model: {deployment?.model?.name}</span>
                )}
                {deployment?.type === deploymentType.DEDICATED && (
                    <span style={{ marginRight: "1.14rem" }}>Accelerator: {deployment?.accelerator?.name}</span>
                )}
                {/* {deployment?.type === deploymentType.DEDICATED && <li style={{ marginRight: "1.14rem" }}>
                    Replicas: ({deployment?.config?.minReplicas} - {deployment?.config?.maxReplicas})
                </li>} */}
                <span style={{ marginRight: "1.14rem" }}>
                    Cooldown time:{" "}
                    {deployment?.config?.cooldownTime === 0
                        ? "0 sec (Always on)"
                        : `${deployment?.config?.cooldownTime} sec`}
                </span>
                {deployment?.type === deploymentType.DEDICATED &&
                    !([tier.ENTERPRISE_SAAS, tier.ENTERPRISE_VPC] as (tier | undefined)[]).includes(userTier) && (
                        <span style={{ marginRight: "1.14rem" }}>
                            Cost:{" "}
                            {deployment.cost.centsPerHour > 0
                                ? `${centsToDollars(deployment.cost.centsPerHour)}/hr`
                                : "--"}
                        </span>
                    )}
                {deployment?.type === deploymentType.DEDICATED && showReplicasCount && (
                    <span style={{ marginRight: "1.14rem" }}>
                        Replicas: {deployment?.config?.minReplicas} → {deployment?.config?.maxReplicas} (Current:{" "}
                        {deployment.currentReplicas})
                    </span>
                )}
            </div>
        </div>
    );
};

const DeploymentView = () => {
    // Meta state:
    const navigate = useNavigate();
    const match = useMatch("/deployments/:uuid");
    const deploymentUUID = match?.params.uuid;

    // Local state:
    const [instructionsModelOpen, setInstructionsModelOpen] = useState(false);
    const [updateDeploymentModalOpen, setUpdateDeploymentModalOpen] = useState(false);

    // Query state:
    const userRole = useUserRole();

    // Fetch deployment for most details:
    const {
        data: deployment,
        isLoading: deploymentIsLoading,
        error: deploymentError,
    } = useDeploymentQuery(deploymentUUID!, {
        enabled: Boolean(deploymentUUID),
        refetchOnWindowFocus: false,
        refetchInterval: 1000 * 10, // 10 seconds
        retry: (failureCount, error) => {
            // TODO: Well, well, our gateway does not return error objects for this...
            if (getErrorMessage(error)?.includes("Could not find deployment")) {
                return false;
            }
            return failureCount < 3;
        },
    });

    // Setup deployment events stream:
    // NOTE: This is a stream that we keep open for the lifetime of this parent component, not just the tab, so the
    // query is invoked here and the data is passed down to the tab component.
    const { data: events } = useDeploymentEventUpdates(deploymentUUID ?? "", {
        enabled: Boolean(deploymentUUID),
        staleTime: Infinity,
        gcTime: Infinity,
    });

    // Error state:
    // TODO: error or error.message?
    const errorMessage = deploymentError
        ? deploymentError
        : deployment?.status === deploymentStatus.ERRORED && deployment?.lastError?.message.length > 0
          ? deployment?.lastError?.message
          : null;

    // If deployment is not found, show a bad link page:
    if (errorMessage === "record not found" || (errorMessage && deployment === undefined)) {
        return <BadLink authenticated={true} />;
    }

    // Panes:
    const deploymentHealthPane = {
        key: "deploymentHealth",
        menuItem: (
            <Menu.Item style={{ height: "2.857rem" }} key={"deploymentHealth"}>
                <Icon name="heartbeat" /> Health
            </Menu.Item>
        ),
        render: () => <DeploymentHealthTab deployment={deployment} />,
    };
    const deploymentEventsPane = {
        key: "deploymentEvents",
        menuItem: (
            <Menu.Item style={{ height: "2.857rem" }} key={"deploymentEvents"}>
                <i className="list alternate outline icon" /> Events
            </Menu.Item>
        ),
        render: () => <DeploymentEventsTab events={events} />,
    };
    const deploymentConfigPane = {
        key: "deploymentConfig",
        menuItem: (
            <Menu.Item style={{ height: "2.857rem" }} key={"deploymentConfig"}>
                <Icon name="wrench" /> Configuration
            </Menu.Item>
        ),
        render: () => <DeploymentConfigTab deployment={deployment} />,
    };

    // Only for use with dedicated deployments:
    const deploymentLogsPane = {
        key: "deploymentLogs",
        menuItem: (
            <Menu.Item style={{ height: "2.857rem" }} key={"deploymentLogs"}>
                <Icon name="terminal" /> Logs
            </Menu.Item>
        ),
        render: () => {
            return <DeploymentLogsTab deploymentUUID={deploymentUUID} deployment={deployment} />;
        },
    };

    return (
        <>
            {deployment && (
                <div style={{ padding: "20px 0" }}>
                    <Breadcrumbs deployment={deployment} />
                    <Divider hidden />
                    <div style={{ margin: "0 20px" }}>
                        <DeploymentHeader deployment={deployment}>
                            {deployment.type === deploymentType.DEDICATED && userRole !== role.READONLY && (
                                <Popup
                                    hoverable
                                    disabled={deployment.updatable}
                                    className={"transition-scale"}
                                    position={"left center"}
                                    trigger={
                                        // To enable the popup when the button is disabled we have to wrap it with a
                                        // div, see: https://github.com/Semantic-Org/Semantic-UI-React/issues/2804
                                        <div>
                                            <Button
                                                disabled={!deployment.updatable}
                                                secondary
                                                onClick={() => setUpdateDeploymentModalOpen(true)}
                                                style={{
                                                    color: SEMANTIC_GREY_DISABLED,
                                                    background: "#E0E1E2",
                                                    whiteSpace: "nowrap",
                                                    height: "2.57143rem",
                                                    marginRight: `${8 / 14}rem`
                                                }}
                                            >
                                                Update Deployment
                                            </Button>
                                        </div>
                                    }
                                    content={
                                        <>Updating this deployment is disabled, please contact support for assistance</>
                                    }
                                />
                            )}
                            <Dropdown
                                // @ts-expect-error React Semantic UI supports this but TS definition does not agree
                                text={
                                    <>
                                        <Icon name="chat" />
                                        Prompt
                                    </>
                                }
                                button
                                direction="left"
                                style={{
                                    color: SEMANTIC_WHITE,
                                    background: SEMANTIC_BLUE,
                                    whiteSpace: "nowrap",
                                    height: "2.57143rem",
                                    marginRight: "0"
                                }}
                            >
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        onClick={() =>
                                            navigate(`/prompt?model=${encodeURIComponent(deployment?.uuid ?? "")}`)
                                        }
                                        disabled={!isDeploymentPromptable(deployment)}
                                    >
                                        Prompt via UI
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => setInstructionsModelOpen(true)}>
                                        Prompt via REST API/SDK/CLI
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </DeploymentHeader>
                        {errorMessage && (
                            <Message negative style={{ marginTop: "1.14286rem" }}>
                                {typeof errorMessage === "string" ? errorMessage : errorMessage.message}
                            </Message>
                        )}
                        {deploymentIsLoading ? (
                            <Loader active inline="centered" />
                        ) : (
                            <Tab
                                panes={
                                    deployment.type === deploymentType.DEDICATED
                                        ? [
                                              deploymentHealthPane,
                                              deploymentEventsPane,
                                              deploymentLogsPane,
                                              deploymentConfigPane,
                                          ]
                                        : [deploymentEventsPane, deploymentConfigPane]
                                }
                                renderActiveOnly={true}
                                menu={{ secondary: true, pointing: true }}
                            />
                        )}
                    </div>
                    {!!deployment && (
                        <>
                            <PromptInstructionsModal
                                open={instructionsModelOpen}
                                setOpen={setInstructionsModelOpen}
                                deployment={deployment}
                            />
                            <CreateUpdateDeploymentModal
                                open={updateDeploymentModalOpen}
                                setOpen={setUpdateDeploymentModalOpen}
                                deployment={deployment}
                            />
                        </>
                    )}
                </div>
            )}
        </>
    );
};

export default DeploymentView;
