import { UiNode, UiText } from "@ory/kratos-client";
import { UiNodeInputAttributes } from "@ory/kratos-client/api";
import { Form, Message } from "semantic-ui-react";
import { FORM_LABELS, FORM_TYPE_OIDC } from "../constants";

const oidcVisibleFields = ["traits.username", "traits.email", "traits.name.first", "traits.name.last", "traits.code"];
const passwordHiddenFields = ["traits.oidc.provider", "traits.oidc.domain", "traits.email_recovery"];

export const isOidcVisibleField = (name: string) => oidcVisibleFields.includes(name);
export const isPasswordHiddenField = (name: string) => passwordHiddenFields.includes(name);

export const fieldSortFunc = (current: UiNode, next: UiNode) => {
    const c = FORM_LABELS[(current.attributes as UiNodeInputAttributes).name]?.priority || 0;
    const n = FORM_LABELS[(next.attributes as UiNodeInputAttributes).name]?.priority || 0;
    return n - c;
};

export const sortFormFields = (fields: UiNode[]) => {
    return fields
        .filter((current) => (current.attributes as UiNodeInputAttributes).type !== "submit")
        .sort(fieldSortFunc);
};

export const sortOIDCFormFields = (fields: UiNode[]) => {
    return fields
        .filter((x) => x.group === FORM_TYPE_OIDC && (x.attributes as UiNodeInputAttributes).type === "submit")
        .sort(fieldSortFunc);
};

/**
 * Kratos hardcodes their error message, so we have to manually override it in the UI.
 * @param message The Kratos message to override
 * @returns text The overridden message text
 */
export const overrideKratosMessage = (message: UiText) => {
    // https://www.ory.sh/docs/kratos/concepts/ui-user-interface#the-provided-credentials-are-invalid-check-for-spelling-mistakes-in-your-password-or-username-email-address-or-phone-number-4000006
    if (message.id === 4000006) {
        return "The email address and password you entered did not match our records. Please double-check and try again.";
    }

    // https://www.ory.sh/docs/kratos/concepts/ui-user-interface#an-account-with-the-same-identifier-email-phone-username--exists-already-4000007
    // https://www.ory.sh/docs/kratos/concepts/ui-user-interface#an-account-with-the-same-identifier-email-phone-username--exists-already-please-sign-in-to-your-existing-account-and-link-your-social-profile-in-the-settings-page-4000027
    if (message.id === 4000007 || message.id === 4000027) {
        return "An account with the same email address already exists. Please re-paste your invite link in your browser and start over.";
    }

    return message.text;
};

export const KratosErrorMessage = (props: { messages?: UiText[] }) => {
    const messages = props.messages;
    if (!messages || messages.length === 0) {
        return null;
    }
    if (messages.length === 1) {
        const message = messages[0];
        return (
            <Message
                attached
                error={message.type === "error"}
                info={message.type === "info"}
                success={message.type === "success"}
                content={overrideKratosMessage(message)}
            />
        );
    }

    return (
        <>
            {messages.map((message) => (
                <Message
                    attached
                    error={message.type === "error"}
                    info={message.type === "info"}
                    success={message.type === "success"}
                    content={overrideKratosMessage(message)}
                />
            ))}
        </>
    );
};

// Used for Login and Recovery but not for Register
export const renderFormFields = (fields: UiNode[]) =>
    fields.map((field) => {
        const { autocomplete, name, type, value } = field.attributes as UiNodeInputAttributes;

        // Switch from user -> mail since we only allow signing with email
        const icon = name === "identifier" ? "mail" : FORM_LABELS[name]?.icon;

        const hidden = isPasswordHiddenField(name);

        const label = FORM_LABELS[name]?.label;
        const error = !!field.messages?.length ? field.messages[0].text : null;

        return (
            <Form.Input
                autoComplete={autocomplete}
                fluid
                error={error}
                key={name}
                type={isPasswordHiddenField(name) ? "hidden" : type}
                icon={icon}
                label={<label style={{ textAlign: "left" }}>{label}</label>}
                iconPosition="left"
                name={name}
                defaultValue={value}
                style={{ display: hidden ? "none" : undefined }}
            />
        );
    });
