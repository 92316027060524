import { UiNode, UiText } from "@ory/kratos-client";
import { UiNodeInputAttributes } from "@ory/kratos-client/api";
import { AxiosError } from "axios";
import React, { forwardRef, useState } from "react";
import { Button, Checkbox, Form, InputOnChangeData, Message } from "semantic-ui-react";
import metrics from "../../../metrics/metrics";
import { capitalize } from "../../../utils/strings";
import { FORM_LABELS, FORM_TYPE_OIDC, FORM_TYPE_PASSWORD } from "../constants";
import {
    KratosErrorMessage,
    isOidcVisibleField,
    isPasswordHiddenField,
    sortFormFields,
    sortOIDCFormFields,
} from "./KratosFormUtils";

const FormFields = (props: {
    fields: UiNode[];
    isOIDCContinuation: boolean;
    email: string;
    firstEmail: string;
    onEmailChange: (e: React.ChangeEvent<HTMLInputElement>, { value }: InputOnChangeData) => void;
    token: string;
}) => (
    <>
        {props.fields.map((field) => {
            const { name, type, value } = field.attributes as UiNodeInputAttributes;

            const removeIcon = name === "traits.name.last" || name === "traits.name.first";
            const icon = removeIcon ? undefined : FORM_LABELS[name]?.icon;
            const iconPosition = removeIcon ? undefined : "left";

            const label = FORM_LABELS[name]?.label;
            const error = !!field.messages?.length ? field.messages[0].text : null;

            const formHidden = (props.isOIDCContinuation && !isOidcVisibleField(name)) || isPasswordHiddenField(name);

            if (name === "traits.email") {
                return (
                    <Form.Input
                        key={name}
                        fluid
                        error={error}
                        type={formHidden ? "hidden" : type}
                        icon={icon}
                        label={<label style={{ textAlign: "left" }}>{label}</label>}
                        iconPosition={iconPosition}
                        name={name}
                        defaultValue={props.firstEmail}
                        onChange={props.onEmailChange}
                    />
                );
            }

            if (name === "traits.email_recovery") {
                return (
                    <Form.Input
                        key={name}
                        name={name}
                        type={"hidden"}
                        value={props.email}
                        style={{ display: "none" }}
                    />
                );
            }

            if (name === "traits.username") {
                return (
                    <Form.Input
                        key={name}
                        name={name}
                        type={"hidden"}
                        value={props.email}
                        style={{ display: "none" }}
                    />
                );
            }

            if (name === "traits.token") {
                return (
                    <Form.Input
                        key={name}
                        name={name}
                        type={"hidden"}
                        value={props.token}
                        style={{ display: "none" }}
                    />
                );
            }

            return (
                <Form.Input
                    key={name}
                    fluid
                    error={error}
                    type={formHidden ? "hidden" : type}
                    icon={icon}
                    label={<label style={{ textAlign: "left" }}>{label}</label>}
                    iconPosition={iconPosition}
                    name={name}
                    defaultValue={value}
                    style={{ display: formHidden ? "none" : undefined }}
                />
            );
        })}
    </>
);

const KratosRegisterForm = forwardRef(
    (
        props: {
            action: string;
            messages?: UiText[];
            fields: UiNode[];
            submitLabel: string;
            formType: string;
            token: string;
            isOIDCContinuation: boolean;
            onSubmit: (e: React.FormEvent<HTMLFormElement>) => void | Promise<void | AxiosError<unknown, any>>;
        },
        buttonRef: React.Ref<Button>,
    ) => {
        const [submitting, setSubmitting] = useState<boolean>(false);
        const [formError, setFormError] = useState<string>("");
        const [agreeToTerms, setAgreeToTerms] = useState<boolean>(false);
        const [firstEmail, setFirstEmail] = useState<string>(
            props.fields?.find(
                (x) => (x.attributes as UiNodeInputAttributes).name.includes("traits.email"),
                // @ts-expect-error - Property 'value' does not exist on type 'UiNodeAttributes'?
            )?.attributes?.value || "",
        );
        const [userContinuingEmail, setUserContinuingEmail] = useState<boolean>(false);

        const fieldsSorted = sortFormFields(props.fields);
        const oidcFields = sortOIDCFormFields(props.fields);
        const oidcContinuationInput = props.fields.find(
            (x) => (x.attributes as UiNodeInputAttributes).name === "provider",
        );

        const [email, setEmail] = useState(
            (
                fieldsSorted.find((x) => (x.attributes as UiNodeInputAttributes).name === "traits.email")
                    ?.attributes as UiNodeInputAttributes
            ).value,
        );

        const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>, { value }: InputOnChangeData) => {
            setEmail(value);
        };

        const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
            setSubmitting(true);
            props
                .onSubmit(e)
                ?.catch((error) => {
                    setFormError(error?.message || "Looks like something went wrong. Try again later.");
                })
                ?.finally(() => setSubmitting(false));
        };

        const hasError = props.fields.some((field) => field.messages.length > 0);

        // For oidc, the user needs to paste the invite link since we don't get it back from Kratos
        if (!props.token && !props.isOIDCContinuation && !props.messages) {
            return (
                <Message negative>
                    <Message.Header>Invalid invite token</Message.Header>
                    <Message.Content>
                        There was no invite token provided. Please check the link you followed and try again.
                    </Message.Content>
                </Message>
            );
        }

        let submitType = props.submitLabel.replace(/\s/g, "");
        submitType = capitalize(submitType);
        if (submitType === "Signup") {
            submitType = "Register";
        }

        return (
            <>
                <KratosErrorMessage messages={props.messages} />
                {formError && <Message error>{formError}</Message>}
                {props.action && (
                    <>
                        {!userContinuingEmail && !props.isOIDCContinuation && !hasError && (
                            <Form>
                                <Form.Input
                                    icon={"mail"}
                                    iconPosition={"left"}
                                    label={<label style={{ textAlign: "left" }}>Email Address</label>}
                                    onChange={(e, { value }) => setFirstEmail(value)}
                                />
                                <Button
                                    className={metrics.BLOCK_AUTO_CAPTURE}
                                    onClick={() => setUserContinuingEmail(true)}
                                    color="black"
                                    fluid
                                    size="large"
                                >
                                    Continue with Email
                                </Button>
                            </Form>
                        )}
                        {(userContinuingEmail || props.isOIDCContinuation || hasError) && (
                            <Form onSubmit={onFormSubmit}>
                                <FormFields
                                    fields={fieldsSorted}
                                    isOIDCContinuation={props.isOIDCContinuation}
                                    email={email}
                                    firstEmail={firstEmail}
                                    onEmailChange={onEmailChange}
                                    token={props.token}
                                />
                                {props.isOIDCContinuation ? (
                                    <Form.Input
                                        key={"provider"}
                                        iconPosition="left"
                                        name={"provider"}
                                        defaultValue={
                                            (oidcContinuationInput?.attributes as UiNodeInputAttributes).value
                                        }
                                        style={{ display: "none" }}
                                    />
                                ) : null}
                                <Checkbox
                                    label={
                                        <label style={{ paddingLeft: `${9 / 14}rem` }}>
                                            By signing up, you agree to our{" "}
                                            <a
                                                href="https://predibase.com/terms-of-service"
                                                target="_blank"
                                                rel="noopener"
                                            >
                                                Terms of Service
                                            </a>{" "}
                                            and{" "}
                                            <a
                                                href="https://predibase.com/privacy-policy"
                                                target="_blank"
                                                rel="noopener"
                                            >
                                                Privacy Policy
                                            </a>
                                            .
                                        </label>
                                    }
                                    value={String(agreeToTerms)}
                                    onChange={() => setAgreeToTerms(!agreeToTerms)}
                                    style={{ marginBottom: "1rem" }}
                                />
                                <Button
                                    ref={buttonRef}
                                    loading={submitting}
                                    disabled={submitting || !agreeToTerms}
                                    className={metrics.BLOCK_AUTO_CAPTURE}
                                    onClick={() => metrics.captureClick("Auth." + submitType)}
                                    color="black"
                                    fluid
                                    size="large"
                                    name="method"
                                    type="submit"
                                    value={props.isOIDCContinuation ? FORM_TYPE_OIDC : FORM_TYPE_PASSWORD}
                                >
                                    {props.submitLabel}
                                </Button>
                            </Form>
                        )}
                    </>
                )}
            </>
        );
    },
);

export default KratosRegisterForm;
