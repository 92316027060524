import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { userList } from "../../../api_generated";
import { useAuth0TokenOptions } from "../../../data";
import { USERS_CONSTANT } from "../../../query";
import { GetUsersResponse, Role } from "../../../types/user";
import { getInviteLink, getRoleAssignments, getRoles, getUsers, GetUsersParams, getUsersV2 } from "./data";

// Constants:
export const ROLES_CONSTANT = "roles";
export const ASSIGNMENTS_CONSTANT = "assignments";
export const INVITE_CONSTANT = "invite";

// Queries:
export const GET_USERS_QUERY_KEY = (params: GetUsersParams) => [USERS_CONSTANT, params];
export const useUsersQuery = (params: GetUsersParams, options?: Partial<UseQueryOptions<GetUsersResponse>>) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<GetUsersResponse>({
        queryKey: GET_USERS_QUERY_KEY(params),
        queryFn: () => getUsers(params, auth0TokenOptions),
        ...options,
    });
};

export const GET_USERS_QUERY_KEY_V2 = (params: GetUsersParams) => [USERS_CONSTANT, "v2", params];
export const useUsersQueryV2 = (params: GetUsersParams, options?: Partial<UseQueryOptions<userList>>) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<userList>({
        queryKey: GET_USERS_QUERY_KEY_V2(params),
        queryFn: () => getUsersV2(params, auth0TokenOptions),
        ...options,
    });
};

export const GET_ROLES_QUERY_KEY = [ROLES_CONSTANT];
export const useRolesQuery = (options?: Partial<UseQueryOptions<Role[]>>) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<Role[]>({
        queryKey: GET_ROLES_QUERY_KEY,
        queryFn: () => getRoles(auth0TokenOptions),
        ...options,
    });
};

export const GET_ROLE_ASSIGNMENTS_QUERY_KEY = (users: string[]) => [ROLES_CONSTANT, ASSIGNMENTS_CONSTANT, users];
export const useRoleAssignmentsQuery = (
    userIDs: string[],
    options?: Partial<UseQueryOptions<Record<string, Role[]>>>,
) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<Record<string, Role[]>>({
        queryKey: GET_ROLE_ASSIGNMENTS_QUERY_KEY(userIDs),
        queryFn: () => getRoleAssignments(userIDs, auth0TokenOptions),
        ...options,
    });
};

export const GET_INVITE_LINK_QUERY_KEY = (roleID: number) => [INVITE_CONSTANT, roleID];
export const useInviteLinkQuery = (roleID: number, options?: Partial<UseQueryOptions<string>>) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<string>({
        queryKey: GET_INVITE_LINK_QUERY_KEY(roleID),
        queryFn: () => getInviteLink(roleID, auth0TokenOptions),
        ...options,
    });
};
