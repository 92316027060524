import { FromSchema } from "json-schema-to-ts";

const ConfigSchema = {
    additionalProperties: true,
    properties: {
        base_model: {
            title: "Name",
            type: "string",
            default: "",
            minLength: 1,
        },
        adapter: {
            default: "lora",
            title: "Adapter",
            type: "string",
            enum: ["lora", "turbo_lora", "turbo"],
        },
        task: {
            default: "instruction_tuning",
            title: "Task",
            type: "string",
            enum: ["completion", "instruction_tuning"],
        },
        rank: {
            default: 16,
            exclusiveMinimum: 0,
            title: "R",
            type: "integer",
            enum: [8, 16, 32, 64, 128],
        },
        target_modules: {
            default: [],
            items: {
                type: "string",
            },
            title: "Target Modules",
            type: "array",
        },
        epochs: {
            default: 3,
            exclusiveMinimum: 0,
            title: "Epochs",
            type: "integer",
        },
        learning_rate: {
            default: 0.0002,
            exclusiveMinimum: 0.0,
            title: "Learning Rate",
            type: "number",
        },
        enable_early_stopping: {
            default: true,
            title: "Enable Early Stopping",
            type: "boolean",
        },
    },
    required: ["base_model"],
    title: "LLMConfig",
    type: "object",
} as const;

export const AdapterConfigSchema = ConfigSchema;
export type AdapterConfigSchemaType = typeof ConfigSchema;
export type AdapterConfig = FromSchema<typeof ConfigSchema>;
