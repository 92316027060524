import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { Form, Grid, Label } from "semantic-ui-react";
import { baseModel } from "../../../api_generated";
import Checkbox from "../../../components/Checkbox";
import Dropdown from "../../../components/Dropdown";
import Input from "../../../components/Input";
import Markdown from "../../../components/Markdown";
import FieldWrapper from "../../../components/forms/json-schema-form/fields/FieldWrapper";
import { titleInlineStyling } from "../../../components/forms/json-schema-form/styling";
import { SEMANTIC_BLUE, SEMANTIC_WHITE } from "../../../utils/colors";
import AdapterTypeRadioGroup from "./AdapterTypeRadioGroup";
import { getInputValue, removeEmptyParameters } from "./ParametersForm";
import { AdapterConfig, AdapterConfigSchema } from "./schema";
import { useConfigState, useDispatch } from "./store";
import { adapterTypes, getSelectedLLM, getTargetModulesDefaultValue, getTaskDropdownOptions } from "./utils";

export const initRemoveParametersUnsupportedByContinuedTraining = (
    config?: Partial<AdapterConfig>,
    continueDatasetsMatch?: boolean,
) => {
    const alwaysRemove = ["rank", "target_modules"];
    const removeIfContinueDatasetsMatch = ["learning_rate", "base_model"];
    const prunedResult: Partial<AdapterConfig> = {};

    for (const key in config) {
        if (alwaysRemove.includes(key) || (continueDatasetsMatch && removeIfContinueDatasetsMatch.includes(key))) {
            continue;
        } else {
            prunedResult[key] = config[key];
        }
    }
    return prunedResult;
};

const ContinueTrainingForm = (props: {
    baseModels?: baseModel[];
    modelDescription: string;
    setModelDescription: React.Dispatch<React.SetStateAction<string>>;
    nextVersion?: number;
    readOnly?: boolean;
    parentAdapterType?: adapterTypes;
    paramsFromJob?: Partial<AdapterConfig>;
    parentAndChildDatasetsMatch?: boolean;
}) => {
    // Parent state:
    const {
        baseModels,
        modelDescription,
        nextVersion,
        readOnly,
        setModelDescription,
        parentAdapterType,
        paramsFromJob,
        parentAndChildDatasetsMatch,
    } = props;

    // Context state:
    const { config, schema } = useConfigState();
    const dispatch = useDispatch();

    // In order to communicate to React Tracked that this component always
    // needs to be re-rendered when the config object changes, we create this
    // unused variable that stringifies the entire object.
    // The bug we were running into was that when submitting the config to the
    // server, we were submitting a stale version of the config that did not get
    // updated until the component was re-rendered.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const configJSON = JSON.stringify(config); // DO NOT DELETE!

    // Local config state:
    const [localConfigState, setLocalConfigState] = useState<Partial<AdapterConfig>>({});

    // Initialize the config in the context:
    useEffect(() => {
        console.log("paramsFromJob", paramsFromJob);
        const initConfig = initRemoveParametersUnsupportedByContinuedTraining(
            removeEmptyParameters(paramsFromJob),
            parentAndChildDatasetsMatch,
        );
        if (initConfig?.enable_early_stopping === undefined) {
            initConfig.enable_early_stopping = AdapterConfigSchema.properties.enable_early_stopping.default;
        }
        dispatch({ type: "INIT", config: initConfig, featureFlags: {} });
    }, [paramsFromJob, parentAndChildDatasetsMatch]);

    // Derived state:
    const selectedBaseModel = useMemo(
        () => getSelectedLLM(config?.base_model, baseModels),
        [config?.base_model, baseModels],
    );
    const hideLearningRate = !readOnly && parentAndChildDatasetsMatch;
    const isInstructModel = Boolean(selectedBaseModel?.canonicalName.toLowerCase().includes("instruct"));

    // Event listeners
    const updateLocalState = (
        path: string,
        value: string | boolean | (string | number | boolean | undefined | null)[],
    ) => {
        if (path === "base_model") {
            const newSelectedBaseModel = getSelectedLLM(value as string, baseModels);
            _.set(localConfigState, "target_modules", getTargetModulesDefaultValue(newSelectedBaseModel));
        }
        setLocalConfigState(_.set(localConfigState, path, value));
    };

    const updateConfigValue = (path: string, convertedValue: string | boolean) => {
        if (path === "base_model") {
            const newSelectedBaseModel = getSelectedLLM(convertedValue as string, baseModels);
            dispatch({
                type: "UPDATE_CONFIG_PROPERTY",
                field: "target_modules",
                value: getTargetModulesDefaultValue(newSelectedBaseModel),
            });
        }
        // If adapter is turbo, completely remove target_modules as a field
        if (path === "adapter" && convertedValue === "turbo") {
            dispatch({ type: "REMOVE_CONFIG_PROPERTY", field: "target_modules" });
        }
        dispatch({ type: "UPDATE_CONFIG_PROPERTY", field: path, value: convertedValue });
    };

    console.log(config);
    return (
        <Grid>
            <Grid.Row columns={2} style={{ paddingBottom: 0, marginTop: `${16 / 14}rem` }}>
                <Grid.Column>
                    <Form>
                        <FieldWrapper
                            title="Adapter Type"
                            description=""
                            path="adapter"
                            schema={{}}
                            style={{ display: "inherit" }}
                        >
                            {/* ? NOTE: For spacing: */}
                            <div style={{ marginBottom: "1rem" }} />
                            <AdapterTypeRadioGroup
                                path="adapter"
                                value={getInputValue("adapter", localConfigState, config)}
                                setConfig={updateConfigValue}
                                setLocalState={updateLocalState}
                                selectedBaseModel={selectedBaseModel}
                                continueFromAdapterType={parentAdapterType}
                                readonly={readOnly}
                            />
                        </FieldWrapper>
                        <FieldWrapper
                            title="Fine-Tuning Task"
                            description="Choose the type of task to fine-tune the base model to complete. [Learn more about the available task types in the docs.](https://docs.predibase.com/sdk-guide/SDKv2/ConfigClasses/FineTuningConfig#task)"
                            path="task"
                            schema={{}}
                            style={{ display: "inherit" }}
                        >
                            <Dropdown
                                path="task"
                                title="Fine-Tuning Task"
                                error={false}
                                multiple={false}
                                fluid={true}
                                options={getTaskDropdownOptions(selectedBaseModel)}
                                value={getInputValue("task", localConfigState, config)}
                                defaultValue={schema?.properties.task.default}
                                readOnly={readOnly}
                                setConfig={updateConfigValue}
                                setLocalState={updateLocalState}
                            />
                        </FieldWrapper>
                        {isInstructModel && (
                            <FieldWrapper path="apply_chat_template" schema={{}} style={{ display: "inherit" }}>
                                <div style={{ display: "inline-block" }}>
                                    <Checkbox
                                        path="apply_chat_template"
                                        error={false}
                                        value={getInputValue("apply_chat_template", localConfigState, config) === true}
                                        readOnly={readOnly}
                                        onChange={(clicked) => {
                                            const newValue = clicked ? true : false;
                                            updateLocalState("apply_chat_template", newValue);
                                            updateConfigValue("apply_chat_template", newValue);
                                        }}
                                        style={{ width: "100%" }}
                                    />
                                    <Label
                                        size="small"
                                        style={{ color: SEMANTIC_WHITE, backgroundColor: SEMANTIC_BLUE }}
                                    >
                                        {" "}
                                        New{" "}
                                    </Label>{" "}
                                    &nbsp;
                                    <span style={{ ...titleInlineStyling }}> Apply Chat Template</span>
                                </div>
                                <div style={{ marginLeft: "2em", marginTop: "0.5em" }}>
                                    <Markdown
                                        secondary={true}
                                        children="Model-specific chat templates are known to boost performance. You may include chat templates in the prompts in your dataset, or we can apply the chat template for you during fine-tuning. Note that you will need include the chat template at inference time. [Learn more about the chat templates.](https://docs.predibase.com/user-guide/fine-tuning/instruction_formats)"
                                    />
                                </div>
                            </FieldWrapper>
                        )}
                        <FieldWrapper
                            title="Epochs"
                            description="Number of times the adapter sees the entire dataset."
                            path="epochs"
                            schema={{}}
                            style={{ display: "inherit" }}
                        >
                            <Input
                                path="epochs"
                                title="Epochs"
                                error={false}
                                value={getInputValue("epochs", localConfigState, config)}
                                defaultValue={schema?.properties.epochs.default}
                                readOnly={readOnly}
                                setConfig={updateConfigValue}
                                setLocalState={updateLocalState}
                                schemaPath="epochs"
                                style={{ width: "100%" }}
                            />
                        </FieldWrapper>
                        {!hideLearningRate && (
                            <FieldWrapper
                                title="Learning Rate"
                                description="Size of the adapter weights update at each batch."
                                path="learning_rate"
                                schema={{}}
                                style={{ display: "inherit" }}
                            >
                                <Input
                                    path="learning_rate"
                                    title="Learning Rate"
                                    error={false}
                                    value={getInputValue("learning_rate", localConfigState, config)}
                                    defaultValue={schema?.properties.learning_rate.default}
                                    readOnly={readOnly}
                                    setConfig={updateConfigValue}
                                    setLocalState={updateLocalState}
                                    schemaPath="learning_rate"
                                    style={{ width: "100%" }}
                                />
                            </FieldWrapper>
                        )}
                        <FieldWrapper
                            title="Enable Early Stopping"
                            description="Enable early stopping of training if validation loss plateaus."
                            path="enable_early_stopping"
                            schema={{}}
                            style={{ display: "inherit" }}
                        >
                            <Checkbox
                                path="enable_early_stopping"
                                label="Enable Early Stopping"
                                error={false}
                                value={getInputValue("enable_early_stopping", localConfigState, config, true)}
                                readOnly={readOnly}
                                onChange={(checked) => {
                                    const newValue = Boolean(checked);
                                    updateLocalState("enable_early_stopping", newValue);
                                    updateConfigValue("enable_early_stopping", newValue);
                                }}
                                style={{ width: "100%" }}
                            />
                        </FieldWrapper>
                        <FieldWrapper
                            title={nextVersion ? `Description for Version ${nextVersion}` : "Description"}
                            description="What you intend to try in this experiment (ex. “first model” or “learning rate auto”)"
                            path="description"
                            schema={{}}
                            style={{ display: "inherit" }}
                        >
                            <Input
                                path="description"
                                title="Description"
                                error={false}
                                value={modelDescription}
                                defaultValue=""
                                readOnly={readOnly}
                                setConfig={() => {}}
                                setLocalState={(path, value) => setModelDescription(value)}
                                schemaPath="description"
                                style={{ width: "100%" }}
                            />
                        </FieldWrapper>
                    </Form>
                </Grid.Column>
                <Grid.Column></Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default ContinueTrainingForm;
