export const cleanCloudSetupErrorMessage = (errorMsg: string) => {
    if (errorMsg.includes("unable to parse arn, arn: invalid prefix")) {
        return "The provided arn has an invalid prefix. Please make sure you copied the full resource arn.";
    }
    if (errorMsg.includes("not authorized to perform: sts:AssumeRole on resource")) {
        return "Unable to assume the provided role to access the account. Please ensure that the Predibase Provisioner Role ARN was set correctly to arn:aws:iam::437263316939:user/predibase-provisioner.";
    }
    if (errorMsg.includes("unable to validate azure credentials")) {
        return "Unable to access the subcription/resource group. Please ensure that the provided credentials are correct and the resource group was created successfully.";
    }
    return errorMsg;
};

export const cloudDetailsTitleStyling = {
    display: "block",
    fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
    fontSize: "1rem",
    fontWeight: "700",
};
