import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { Button, Divider, Message } from "semantic-ui-react";
import urlJoin from "url-join";
import { useAuth0TokenOptions } from "../../../../data";
import { GET_ENVIRONMENT_QUERY_KEY } from "../../../../query";
import { getDocsHome } from "../../../../utils/api";
import { SEMANTIC_GREY } from "../../../../utils/colors";
import { getErrorMessage } from "../../../../utils/errors";
import { AzureRegionsType, initializeEnvironment, provisionEnvironment } from "../../data";
import { cleanCloudSetupErrorMessage } from "../utils";
import AzureDetailsForm from "./AzureDetailsForm";

const AzureSetupView = (props: { envShortCode: string; envRegion?: AzureRegionsType }) => {
    // Parent state:
    const { envShortCode, envRegion } = props;

    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    // Local state:
    const [appID, setAppID] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [tenantID, setTenantID] = useState<string>("");
    const [subscriptionID, setSubscriptionID] = useState<string>("");
    const [resourceGroup, setResourceGroup] = useState<string>("predibase-" + envShortCode);
    const [errorMsg, setErrorMsg] = useState<string | null>("");
    const [environmentInitialized, setEnvironmentInitialized] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    // Query state:
    const queryClient = useQueryClient();
    const { mutate: mutateInitializeEnvironment, reset: resetInitializeEnvironmentMutation } = useMutation({
        mutationFn: () =>
            initializeEnvironment(
                envShortCode,
                {
                    cloud: "azure",
                    cloudRegion: envRegion!,
                    cloudCredentialsAzure: { appID, password, tenantID, subscriptionID, resourceGroup },
                },
                auth0TokenOptions,
            ),
        onError: (error) => {
            setEnvironmentInitialized(false);
            setErrorMsg(getErrorMessage(error));
        },
        onSuccess: () => {
            setEnvironmentInitialized(true);
            queryClient.invalidateQueries({ queryKey: GET_ENVIRONMENT_QUERY_KEY(envShortCode) });
        },
        onSettled: () => {
            setLoading(false);
        },
    });

    const { mutate: mutateProvisionEnvironment, reset: resetProvisionMutation } = useMutation({
        mutationFn: () => provisionEnvironment(envShortCode, auth0TokenOptions),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: GET_ENVIRONMENT_QUERY_KEY(envShortCode) });
        },
    });

    if (!envRegion) {
        return null;
    }

    return (
        <>
            <AzureDetailsForm
                region={envRegion}
                envShortCode={envShortCode}
                setAppID={setAppID}
                setPassword={setPassword}
                setTenantID={setTenantID}
                setSubscriptionID={setSubscriptionID}
                setResourceGroup={setResourceGroup}
            />
            <div style={{ color: SEMANTIC_GREY, fontSize: "12" }}>
                {environmentInitialized ? (
                    <Button
                        primary
                        style={{ width: "100%" }}
                        fluid
                        size="large"
                        name="method"
                        type="submit"
                        onClick={() => {
                            resetProvisionMutation();
                            mutateProvisionEnvironment();
                        }}
                    >
                        Create Predibase Environment
                    </Button>
                ) : (
                    <Button
                        disabled={!appID || !password || !tenantID || !subscriptionID || !resourceGroup}
                        secondary
                        loading={loading}
                        style={{ width: "100%" }}
                        fluid
                        size="large"
                        name="method"
                        type="submit"
                        onClick={() => {
                            setLoading(true);
                            resetInitializeEnvironmentMutation();
                            mutateInitializeEnvironment();
                        }}
                    >
                        Validate Azure Credentials
                    </Button>
                )}
                {errorMsg && !environmentInitialized && (
                    <Message negative>
                        <Message.Header>Error</Message.Header>
                        <div>{cleanCloudSetupErrorMessage(errorMsg)}</div>
                    </Message>
                )}
            </div>
            <Divider hidden />
            For more detailed instructions/screenshots, see our{" "}
            {
                <a
                    href={urlJoin(getDocsHome(), "/user-guide/admin/vpc-provisioning/azure")}
                    target="_blank"
                    rel="noopener"
                >
                    docs
                </a>
            }
            .
        </>
    );
};

export default AzureSetupView;
