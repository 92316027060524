import { getTraceId } from "../../api/trace";
import {
    completeSignupRequest,
    validateInviteRequest,
    validateInviteResponse,
    validateUserRequest,
    validateUserResponse,
} from "../../api_generated";
import { Auth0TokenOptions } from "../../data";
import metrics from "../../metrics/metrics";
import { createV2APIServer, redirectIfSessionInvalid } from "../../utils/api";
import { getErrorMessage } from "../../utils/errors";

export const validateInviteToken = async (inviteToken: string) => {
    const endpoint = "/auth/validate-invite";
    // Since users are anonymous, don't need to fetch a token from Auth0:
    const apiServer = await createV2APIServer();

    const body: validateInviteRequest = { inviteToken };
    return apiServer
        .post<validateInviteResponse>(endpoint, body)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "POST",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const moveUser = async (inviteToken: string, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = "users/move";
    const apiServer = await createV2APIServer(auth0TokenOptions);

    return apiServer.post(endpoint, { inviteToken }).catch((error) => {
        const errorMsg = getErrorMessage(error) ?? "";
        metrics.captureError("api_error", errorMsg, {
            method: "POST",
            endpoint,
            trace_id: getTraceId(error),
        });
        redirectIfSessionInvalid(errorMsg);
        throw errorMsg;
    });
};

export const validateUser = async (inviteToken?: string, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = "/auth/validate-user";
    const apiServer = await createV2APIServer(auth0TokenOptions);

    const body: validateUserRequest = { inviteToken };
    return apiServer
        .post<validateUserResponse>(endpoint, body)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "POST",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const completeSignup = async (signupRequest: completeSignupRequest, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = "auth/complete-signup";
    // TODO: This actually won't work without auth0 lol
    const apiServer = await createV2APIServer(auth0TokenOptions);

    return apiServer.post(endpoint, signupRequest).catch((error) => {
        const errorMsg = getErrorMessage(error) ?? "";
        metrics.captureError("api_error", errorMsg, {
            method: "POST",
            endpoint,
            trace_id: getTraceId(error),
        });
        redirectIfSessionInvalid(errorMsg);
        throw errorMsg;
    });
};
