import { useAuth0, User } from "@auth0/auth0-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Button, Form, Header, Loader, Message } from "semantic-ui-react";
import { validateUserResponse } from "../../api_generated";
import { useAuth0TokenOptions } from "../../data";
import { SESSION_STATE } from "../../state/global";
import { PREDIBASE_LIGHT_GRAY } from "../../utils/colors";
import { getErrorMessage } from "../../utils/errors";
import { isKratosSession } from "../../utils/kratos";
import { useGetSignOut } from "../../utils/signout";
import { useInviteParams } from "../hooks";
import { completeSignup, moveUser } from "./data";
import { useValidateInviteTokenQuery, useValidateUserQuery } from "./query";

const Container = (props: { children: React.ReactNode }) => {
    return (
        <div
            style={{
                minHeight: "100vh",
                background: PREDIBASE_LIGHT_GRAY,
                display: "flex",
                justifyContent: "center",
                padding: "20px",
            }}
        >
            <div
                style={{
                    display: "flex",
                    width: "50vw",
                    maxWidth: "400px",
                    alignSelf: "center",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                {props.children}
            </div>
        </div>
    );
};

const SwitchTenantForm = (props: { inviteToken: string; tenantName: string }) => {
    // Parent state:
    const { inviteToken, tenantName } = props;

    // Router state:
    const navigate = useNavigate();

    // Query state:
    const queryClient = useQueryClient();

    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    // Local state:
    const [error, setError] = useState<string | undefined>(undefined);

    const inviteTokenHook = useInviteParams();

    // Query state:
    const { mutate: switchTenantMutate } = useMutation({
        mutationFn: () => moveUser(inviteToken, auth0TokenOptions),
        onSuccess: () => {
            // Clear out invite token so app is in clean state
            inviteTokenHook.clearInviteParams();
            // Clear any cached query data to reset the app:
            queryClient.invalidateQueries();
            queryClient.clear();
            navigate("/");
        },
        onError: (error) => {
            setError(error?.message || "Looks like something went wrong. Try again");
        },
    });

    return (
        <div
            style={{
                minHeight: "100vh",
                background: PREDIBASE_LIGHT_GRAY,
                display: "flex",
                justifyContent: "center",
                padding: "20px",
                flexDirection: "column",
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    alignSelf: "center",
                    alignItems: "center",
                    gap: "1rem",
                    marginBottom: "2rem",
                }}
            >
                <img src={"/logos/predibase/predibase.svg"} width={70} height={70} alt="" />
                <Header as="h2" textAlign="center">
                    Continue to join your team, <strong>{tenantName}</strong>, on Predibase!
                </Header>
                {error && <Message error>{error}</Message>}
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    maxWidth: "600px",
                    alignSelf: "center",
                    alignItems: "center",
                    gap: "1rem",
                }}
            >
                <div style={{ marginBottom: "20px" }}>
                    Warning: You will be leaving your current team and lose access to any existing artifacts created in
                    Predibase (adapters, datasets, and deployments).
                </div>
                <Button
                    fluid
                    size="large"
                    color="black"
                    onClick={() => {
                        switchTenantMutate();
                    }}
                    style={{ maxWidth: "400px" }}
                >
                    Leave my current team and join {tenantName}
                </Button>
                <button
                    className={`button-reset`}
                    onClick={() => {
                        inviteTokenHook.clearInviteParams();
                        navigate("/");
                    }}
                >
                    Nevermind
                </button>
            </div>
        </div>
    );
};

const FinishSigningUpForm = (props: { inviteToken?: string; tenantName?: string }) => {
    // Route state:
    const navigate = useNavigate();

    // Query state:
    const queryClient = useQueryClient();

    // Parent state:
    const { inviteToken, tenantName } = props;

    // Auth0 state:
    const { loginWithRedirect, user } = useAuth0();
    const auth0TokenOptions = useAuth0TokenOptions();
    const signout = useGetSignOut();
    const inviteTokenHook = useInviteParams();

    // Local state:
    const [error, setError] = useState<string | undefined>(undefined);
    const [formErrors, setFormErrors] = useState<Record<string, string>>({});
    const [firstName, setFirstName] = useState<string>(user?.given_name || "");
    const [lastName, setLastname] = useState<string>(user?.family_name || "");
    const [company, setCompany] = useState<string>(tenantName || "");
    const [role, setRole] = useState<string>("");
    const [hearAboutUs, setHearAboutUs] = useState<string>("");

    // Query state:
    const {
        mutate: finishSigningMutate,
        reset: finishSigningReset,
        isPending: finishSigningIsPending,
    } = useMutation({
        mutationFn: () => {
            const inviteUTMParams = inviteTokenHook.utmParams;
            const params = new URLSearchParams(inviteUTMParams);
            const utm_source = params.get("utm_source") || "";
            const utm_medium = params.get("utm_medium") || "";
            const utm_campaign = params.get("utm_campaign") || "";

            return completeSignup({ firstName, lastName, company, role, hearAboutUs, inviteToken, utm_source, utm_campaign, utm_medium }, auth0TokenOptions);
        },
        onSuccess: () => {
            // Clear out invite token so user can switch tenants if needed
            inviteTokenHook.clearInviteParams();
            // Clear any cached query data to reset the app:
            queryClient.invalidateQueries();
            queryClient.clear();
            // Forces Auth0 to refresh the user session:
            // TODO: Will this be disruptive? Do we need to manually fetch this data?
            loginWithRedirect();
            navigate("/");
        },
        onError: (error) => {
            setError(error?.message || "Looks like something went wrong. Try again");
        },
    });

    const formErrorsMessage = Object.keys(formErrors).length === 0 ? "" : formErrors[Object.keys(formErrors)[0]];

    return (
        <Container>
            <img src={"/logos/predibase/predibase.svg"} width={70} height={70} alt="" />
            <Header as="h2" textAlign="center">
                Welcome to Predibase!
            </Header>
            {error && <Message error>{error}</Message>}
            {formErrorsMessage && <Message error>{formErrorsMessage}</Message>}
            <Form
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    marginTop: "1rem",
                    gap: "0.5rem",
                }}
            >
                <Form.Input
                    label="First Name"
                    placeholder="First Name"
                    onChange={(e, { value }) => {
                        setFirstName(value);
                        setFormErrors((formErrors) => {
                            const { firstName, ...rest } = formErrors;
                            return rest;
                        });
                    }}
                    value={firstName}
                    error={Object.keys(formErrors).includes("firstName")}
                />
                <Form.Input
                    label="Last Name"
                    placeholder="Last Name"
                    onChange={(e, { value }) => {
                        setLastname(value);
                        setFormErrors((formErrors) => {
                            const { lastName, ...rest } = formErrors;
                            return rest;
                        });
                    }}
                    value={lastName}
                    error={Object.keys(formErrors).includes("lastName")}
                />
                <Form.Input
                    label="Company"
                    placeholder="Company"
                    onChange={(e, { value }) => {
                        setCompany(value);
                        setFormErrors((formErrors) => {
                            const { company, ...rest } = formErrors;
                            return rest;
                        });
                    }}
                    value={company}
                    disabled={!!tenantName}
                    error={Object.keys(formErrors).includes("company")}
                />
                <Form.Input
                    label="Role"
                    placeholder="Ex. software engineer, data scientist"
                    onChange={(e, { value }) => {
                        setRole(value);
                        setFormErrors((formErrors) => {
                            const { role, ...rest } = formErrors;
                            return rest;
                        });
                    }}
                    value={role}
                    error={Object.keys(formErrors).includes("role")}
                />
                {!inviteToken && (
                    <Form.Input
                        label="How did you hear about us?"
                        placeholder="Ex. LinkedIn, Twitter, Friend"
                        onChange={(e, { value }) => {
                            setHearAboutUs(value);
                            setFormErrors((formErrors) => {
                                const { hearAboutUs, ...rest } = formErrors;
                                return rest;
                            });
                        }}
                        value={hearAboutUs}
                        error={Object.keys(formErrors).includes("hearAboutUs")}
                    />
                )}
                <Form.Button
                    fluid
                    size="large"
                    content="Continue"
                    color="black"
                    onClick={() => {
                        let newFormErrors = {} as Record<string, string>;
                        if (!firstName) {
                            newFormErrors.firstName = "Please enter a first name.";
                        }

                        if (!lastName) {
                            newFormErrors.lastName = "Please enter a last name.";
                        }

                        if (!company) {
                            newFormErrors.company = "Please enter a company.";
                        }

                        if (!role) {
                            newFormErrors.role = "Please enter a role.";
                        }

                        if (!inviteToken && !hearAboutUs) {
                            newFormErrors.hearAboutUs = "Please enter how you heard about us.";
                        }

                        if (Object.keys(newFormErrors).length > 0) {
                            setFormErrors(newFormErrors);
                            return;
                        }

                        finishSigningReset();
                        finishSigningMutate();
                    }}
                    loading={finishSigningIsPending}
                    enabled={!finishSigningIsPending}
                />
                <button
                    className="button-reset"
                    onClick={() => {
                        signout();
                    }}
                >
                    Cancel
                </button>
            </Form>
        </Container>
    );
};

const VerifyEmail = (props: { email: string }) => {
    const { email } = props;
    const signout = useGetSignOut();

    return (
        <Container>
            <img src={"/logos/predibase/predibase.svg"} width={70} height={70} alt="" />
            <Header as="h2" textAlign="center">
                Verify your email address
            </Header>
            <p>We just sent a verification link to</p>
            <strong>{email}</strong>
            <br />
            <p>Click the link to complete the verification process. You might need to check your spam folder.</p>
            <p>
                Already have an account?{" "}
                <button
                    className="button-reset"
                    style={{ paddingLeft: 0 }}
                    onClick={() => {
                        signout();
                    }}
                >
                    Sign In
                </button>
            </p>
        </Container>
    );
};

const WelcomeView = () => {
    // Router/URL state:
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const returnTo = searchParams.get("return_to") || undefined;
    const inviteTokenHook = useInviteParams();
    const inviteToken = inviteTokenHook.inviteToken;

    // Local state:
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [isNewUser, setIsNewUser] = useState<boolean>(false);
    const [showSwitchTenant, setShowSwitchTenant] = useState<boolean>(false);

    // Global state
    const [session] = useRecoilState(SESSION_STATE);
    let email = "";
    let emailVerified = false;

    if (session) {
        const isKratosSess = isKratosSession(session);
        email = isKratosSess ? session?.identity?.traits?.email : (session as User)?.email;
        emailVerified = isKratosSess ? true : Boolean((session as User)?.email_verified);
    }

    // Query state:
    const {
        data: validatedUser,
        isLoading: isValidatedUserLoading,
        error: validatedUserError,
    } = useValidateUserQuery(inviteToken);
    const {
        data: validatedInvite,
        isLoading: isValidatedInviteLoading,
        error: validatedInviteError,
    } = useValidateInviteTokenQuery(inviteToken);

    useEffect(() => {
        if (validatedUser) {
            // If we've already validated the user, we can skip the rest of the validation:
            switch (validatedUser.status) {
                case validateUserResponse.status.ACCOUNTS_MERGED:
                    // Clear any saved invite tokens:
                    inviteTokenHook.clearInviteParams();
                    // Reload the Auth0 JWT in case we combined accounts.
                    // Forces Auth0 to refresh the user session:
                    // TODO: Would be nice to do this without hard refreshing the page.
                    window.location.href = returnTo ?? "/";
                    break;
                case validateUserResponse.status.RECOGNIZED:
                    // Clear any saved invite tokens:
                    inviteTokenHook.clearInviteParams();
                    // Send user to the App since we know who they are:
                    navigate(returnTo ?? "/");
                    break;
                case validateUserResponse.status.SWITCH_TENANT:
                    setShowSwitchTenant(true);
                    setErrorMessage(undefined);
                    break;
                case validateUserResponse.status.NEW_USER:
                    setIsNewUser(true);
                    setErrorMessage(undefined);
                    break;
                default:
                    setErrorMessage("Looks like something went wrong. Try again");
                    break;
            }
        }
    }, [validatedUser, validatedUserError]);

    if (isValidatedUserLoading || isValidatedInviteLoading) {
        return <Loader inline></Loader>;
    }

    const error = validatedUserError || validatedInviteError || errorMessage;

    if (emailVerified === false) {
        return <VerifyEmail email={email} />;
    }

    return (
        <>
            {isNewUser && <FinishSigningUpForm inviteToken={inviteToken} tenantName={validatedInvite?.tenantName} />}
            {showSwitchTenant && inviteToken !== undefined && (
                <SwitchTenantForm inviteToken={inviteToken} tenantName={validatedInvite?.tenantName || ""} />
            )}
            {error && (
                <div style={{ padding: "20px" }}>
                    {/* TODO: Could do something more intelligent based on the error code, e.g:
                    Please check the invite link and try again, or contact the person who invited you. */}
                    <Header as={"h2"}>Error</Header>
                    <Message negative>{getErrorMessage(error)}</Message>
                </div>
            )}
        </>
    );
};

export default WelcomeView;
