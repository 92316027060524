import { Address, PaymentMethod, Stripe as StripeJS } from "@stripe/stripe-js";
import { getTraceId } from "../../../api/trace";
import { Auth0TokenOptions } from "../../../data";
import metrics from "../../../metrics/metrics";
import { TaxIDData } from "../../../types/billing";
import { createV1APIServer, redirectIfSessionInvalid } from "../../../utils/api";
import { getErrorMessage } from "../../../utils/errors";

// stripe-js does not specify all Stripe types: https://github.com/stripe/stripe-js/issues/62
import type { Stripe as StripeNodeTypes } from "stripe";

export const createSetupIntent = async (auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `/payments/intent`;
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .post(endpoint)
        .then((res) => {
            return {
                clientSecret: res.data.clientSecret,
            };
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "POST",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export interface PaymentMethodParams {
    paymentMethod: string | PaymentMethod | null;
    address: Address;
    name: string;
    phone?: string;
    taxIDData?: TaxIDData;
    inMandate?: boolean;
}
export const createPaymentMethod = async (
    { paymentMethod, address, name, phone, taxIDData, inMandate }: PaymentMethodParams,
    auth0TokenOptions?: Auth0TokenOptions,
) => {
    const endpoint = `/payments/method`;
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .post(endpoint, {
            paymentMethod,
            name,
            phone,
            address,
            taxIDData,
            inMandate,
        })
        .then((res) => {
            return {
                clientSecret: res.data.clientSecret,
            };
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            const stripeErrorMsg = error.response.data.message;
            const stripeErrorCode = error.response.data.code;
            metrics.captureError("api_error", `${stripeErrorCode} - ${stripeErrorMsg} - ${errorMsg}`, {
                method: "POST",
                endpoint,
            });
            redirectIfSessionInvalid(errorMsg);
            throw stripeErrorMsg || errorMsg;
        });
};

// https://github.com/stripe/stripe-node/issues/907#issuecomment-637163623
export type StripeCustomerUnionType = StripeNodeTypes.Customer | StripeNodeTypes.DeletedCustomer;
export const getCustomer = async (auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `/payments/customer`;
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .get<StripeCustomerUnionType>(endpoint)
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "GET",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            // eslint-disable-next-line no-throw-literal
            throw {
                status: error.response.status,
                message: errorMsg,
            };
        });
};

export const getSetupIntent = (stripe: StripeJS, setupIntentClientSecret: string) => {
    // Docs: https://stripe.com/docs/js/setup_intents/retrieve_setup_intent
    return stripe
        .retrieveSetupIntent(setupIntentClientSecret)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            const stripeErrorMsg = error?.response?.data?.message;
            const stripeErrorCode = error?.response?.data?.code;
            metrics.captureError("api_error", `${stripeErrorCode} - ${stripeErrorMsg} - ${errorMsg}`, {
                endpoint: "stripe-retrieve-setup-intent",
            });
            redirectIfSessionInvalid(errorMsg);
            throw stripeErrorMsg || errorMsg;
        });
};
