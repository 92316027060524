import { Dispatch, SetStateAction, useState } from "react";
import { useRecoilState } from "recoil";
import { Loader, Popup, Table } from "semantic-ui-react";
import metrics from "../../../metrics/metrics";
import { USER_STATE } from "../../../state/global";
import { KratosUser, Role } from "../../../types/user";
import { capitalize } from "../../../utils/strings";
import EditUserModal from "./EditUserModal";
import RoleSelectorWithAction from "./RoleSelectorWithAction";

const RowSelector = (props: {
    user: KratosUser;
    roleAssignments: Record<string, Role[]>;
    allPossibleRoles: Role[];
    isViewerAdmin: boolean;
    refreshFunc: () => void;
    setErrorMessage: Dispatch<SetStateAction<string | null>>;
}) => {
    // Parent state:
    const { user, roleAssignments, allPossibleRoles, isViewerAdmin, refreshFunc, setErrorMessage } = props;

    // Derived state:
    const rolesForUser = roleAssignments[user.id] as Role[] | undefined;
    // Get the current (i.e. last assigned) role for the user: -- TODO: THIS IS A BIG HEADACHE
    const currentRole = rolesForUser?.[rolesForUser.length - 1] || allPossibleRoles.find((x) => x.name === "USER");
    const isAdmin = currentRole?.name?.includes("ADMIN");

    if (isAdmin) {
        return <>Admin</>;
    } else if (isViewerAdmin) {
        return (
            <RoleSelectorWithAction
                user={user}
                // TODO(hungcs): Remove this filtering when members table is fully refactored.
                allPossibleRoles={allPossibleRoles}
                currentRole={currentRole}
                refreshFunc={refreshFunc}
                setErrorMessage={setErrorMessage}
            />
        );
    } else {
        return (
            <Popup
                className={"transition-scale"}
                trigger={
                    <span>
                        {capitalize(allPossibleRoles.find((x) => x.id === currentRole?.id)?.name || "USER", true)}
                    </span>
                }
                content={"Only admins can change user roles."}
            />
        );
    }
};

const MembersTable = (props: {
    users?: KratosUser[];
    onUserSubmit: () => void;
    allPossibleRoles: Role[];
    roleAssignments: Record<string, Role[]>;
    refreshFunc: () => void;
    setErrorMessage: Dispatch<SetStateAction<string | null>>;
}) => {
    // Parent state:
    const { users, onUserSubmit, allPossibleRoles, roleAssignments, refreshFunc, setErrorMessage } = props;

    // Recoil state:
    const [userContext] = useRecoilState(USER_STATE);

    // Local state:
    const [editUserModalOpen, setEditUserModalOpen] = useState(false);
    const [activeUserSelection, setActiveUserSelection] = useState<KratosUser | null>(null);

    // Derived state:
    const isViewerAdmin = Boolean(userContext?.isSystemUser);

    if (users === undefined) {
        return <Loader active />;
    }

    return (
        <>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Username</Table.HeaderCell>
                        <Table.HeaderCell>Email Address</Table.HeaderCell>
                        <Table.HeaderCell>Auth Method</Table.HeaderCell>
                        <Table.HeaderCell>Role</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {users.map((user) => {
                        let name = `${user.traits.name.first || ""} ${user.traits.name.last || ""}`;
                        if (!user.traits.name.first && !user.traits.name.last) {
                            name = "--";
                        }
                        if (user.id === userContext?.uuid) {
                            name += " (You!)";
                        }

                        return (
                            <Table.Row key={user.id}>
                                <Table.Cell>
                                    <button
                                        className={`${metrics.BLOCK_AUTO_CAPTURE} button-reset button-reset-table`}
                                        id="editUserModal"
                                        onClick={() => {
                                            setActiveUserSelection(user);
                                            setEditUserModalOpen(true);
                                        }}
                                    >
                                        {name || "[none]"}
                                    </button>
                                </Table.Cell>
                                <Table.Cell>{user.traits.username}</Table.Cell>
                                <Table.Cell>{user.traits.email}</Table.Cell>
                                <Table.Cell>{user.traits.oidc?.provider || "Password"}</Table.Cell>
                                <Table.Cell>
                                    <RowSelector
                                        user={user}
                                        roleAssignments={roleAssignments}
                                        allPossibleRoles={allPossibleRoles}
                                        isViewerAdmin={isViewerAdmin}
                                        refreshFunc={refreshFunc}
                                        setErrorMessage={setErrorMessage}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
            <EditUserModal
                open={editUserModalOpen}
                setOpen={setEditUserModalOpen}
                onSubmit={onUserSubmit}
                user={activeUserSelection!}
            />
        </>
    );
};

export default MembersTable;
