import { adapterVersionListItem, finetuningJobStatus, repo } from "../../api_generated";

export const isTerminalJobStatus = (status?: finetuningJobStatus): boolean => {
    return (
        status === finetuningJobStatus.ERRORED ||
        status === finetuningJobStatus.COMPLETED ||
        status === finetuningJobStatus.CANCELED ||
        status === finetuningJobStatus.STOPPED
    );
};

export const isFailedOrCanceledJobStatus = (status?: string): boolean => {
    return status === finetuningJobStatus.ERRORED || status === finetuningJobStatus.CANCELED;
};

export const getNextAdapterVersion = (adapterRepo?: repo) => {
    const adapterVersions = Array.isArray(adapterRepo?.versions) ? adapterRepo?.versions : [];
    const versions = adapterVersions.map((v) => v.tag);
    return Math.max(...versions, 0) + 1;
};

// TODO: Remove "null" union type when we fix the backend :(
export const getlatestAdapterInRepo = (
    repoVersions: null | adapterVersionListItem[],
    filterPredicate?: Parameters<Array<adapterVersionListItem>["filter"]>[0],
) => {
    const versions = repoVersions?.filter((v, i, a) => filterPredicate === undefined || filterPredicate(v, i, a));
    if (versions === undefined || versions?.length === 0) {
        return undefined;
    }

    const sortedVersions = versions.sort((v1, v2) => {
        const tag1 = Number(v1.tag);
        const tag2 = Number(v2.tag);
        if (tag1 < tag2) {
            return -1;
        }
        if (tag1 > tag2) {
            return 1;
        }
        return 0;
    });

    return sortedVersions.at(-1);
};
