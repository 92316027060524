import { useMutation } from "@tanstack/react-query";
import _ from "lodash";
import React, { useState } from "react";
import { Dropdown } from "semantic-ui-react";
import { role } from "../../../api_generated";
import { useAuth0TokenOptions } from "../../../data";
import metrics from "../../../metrics/metrics";
import { KratosUser, Role } from "../../../types/user";
import { getErrorMessage } from "../../../utils/errors";
import { capitalize } from "../../../utils/strings";
import { grantRole } from "./data";

const collator = new Intl.Collator("en", { numeric: true, sensitivity: "base" });

const getRoleOptions = (roles: Role[]) => {
    return roles
        .map((role, idx) => {
            return {
                key: idx,
                text: capitalize(role.name, true),
                value: role.id,
            };
        })
        .sort((a, b) => collator.compare(a.text, b.text));
};

const RoleSelectorWithAction = (props: {
    allPossibleRoles: Role[];
    user: KratosUser;
    currentRole?: Role;
    refreshFunc: Function;
    setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>;
}) => {
    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    // Parent state:
    const { allPossibleRoles, user, currentRole, refreshFunc, setErrorMessage } = props;

    // Derived state:
    const [selectedRole, setSelectedRole] = useState<Role | undefined>(
        allPossibleRoles.find((x) => x.id === currentRole?.id) || allPossibleRoles[0],
    );
    const roleIDMap = new Map<number, Role>(allPossibleRoles.map((role) => [role.id, role]));
    const dropdownOptions = getRoleOptions(allPossibleRoles);

    // Query state:
    const { mutate: mutateGrantRole } = useMutation({
        mutationFn: (selectedRole: Role) =>
            grantRole(
                {
                    userID: user.id,
                    userName: user.traits.username,
                    roleID: selectedRole?.id,
                    roleName: selectedRole?.name,
                },
                auth0TokenOptions,
            ),
        onSuccess: (_, variables) => {
            const selectedRoleID = variables.id;
            setSelectedRole(roleIDMap.get(selectedRoleID));
            refreshFunc();
            setErrorMessage(null);
        },
        onError: (error) => {
            setErrorMessage(getErrorMessage(error));
        },
    });

    // If the user described in this row is an admin, don't allow changing its role:
    if (selectedRole?.name?.toUpperCase() === role.ADMIN) {
        return <>Admin</>;
    }
    // If the viewer of the table is readonly, don't allow changing the role at all:
    if (currentRole?.name?.toUpperCase() === role.READONLY) {
        return <>{_.capitalize(selectedRole?.name ?? "")}</>;
    }

    return (
        <Dropdown
            className={metrics.BLOCK_AUTO_CAPTURE}
            placeholder="Select Role"
            fluid
            search
            selection
            options={dropdownOptions}
            value={selectedRole?.id}
            onChange={(_, { value: roleID }) => {
                mutateGrantRole(allPossibleRoles.find((x) => x.id === roleID)!);
            }}
        />
    );
};

export default RoleSelectorWithAction;
