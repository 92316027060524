import { capitalize } from "lodash";
import { role } from "../../../api_generated";



export const generateUserRoleDropdownOptions = (userRole?: string) => {
    const roleKeys = [role.READONLY];

    if (userRole !== role.READONLY) {
        roleKeys.push(role.USER);
    }

    if (userRole === role.ADMIN || userRole === role.SUPPORT) {
        roleKeys.push(role.ADMIN);
    }

    if (userRole === role.SUPPORT) {
        roleKeys.push(role.SUPPORT);
    }

    return roleKeys.map((key) => ({
        text: capitalize(key.toLowerCase()),
        value: key.toUpperCase(),
    })).reverse();
};

export const cleanAuthMethods = (authMethods: string[]) => {
    return authMethods.map((method) => {
        switch (method) {
            case "auth0":
                return "Email/Password";
            case "google-oauth2":
                return "Google";
            case "github":
                return "Github";
            default:
                return capitalize(method.toLowerCase());
        }
    });
};
