import { useSearchParams } from "react-router-dom";
import { Divider, Header } from "semantic-ui-react";
import { PREDIBASE_LIGHT_GRAY } from "../../utils/colors";
import { useInviteParams } from "../hooks";
import AuthenticationOptions from "./AuthenticationOptions";

import "./SigninView.css";

const SigninView = () => {
    // URL state:
    const [searchParams] = useSearchParams();
    const returnTo = searchParams.get("return_to") || "/";
    const inviteTokenHook = useInviteParams();
    const inviteToken = inviteTokenHook.inviteToken;

    return (
        <div
            style={{
                minHeight: "100vh",
                background: PREDIBASE_LIGHT_GRAY,
                display: "flex",
                justifyContent: "center",
                padding: "20px",
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    maxWidth: "400px",
                    alignSelf: "center",
                    alignItems: "center",
                    gap: "1rem",
                }}
            >
                <img src={"/logos/predibase/predibase.svg"} width={70} height={70} alt="" />
                <>
                    <Header as="h2" textAlign="center">
                        Sign in to Predibase
                    </Header>
                    <AuthenticationOptions returnTo={returnTo} inviteToken={inviteToken} />
                </>
            </div>
            <Divider hidden />
        </div>
    );
};

export default SigninView;
