import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { Button, Divider, Form, Message } from "semantic-ui-react";
import { useAuth0TokenOptions } from "../../../../data";
import { GET_ENVIRONMENT_QUERY_KEY } from "../../../../query";
import { getErrorMessage } from "../../../../utils/errors";
import { AWSRegionsType, initializeEnvironment, provisionEnvironment } from "../../data";
import { cleanCloudSetupErrorMessage, cloudDetailsTitleStyling } from "../utils";
import AWSDetailsForm from "./AWSDetailsForm";

const AWSSetupView = (props: { envShortCode: string; envRegion?: AWSRegionsType }) => {
    // Parent state:
    const { envShortCode, envRegion } = props;

    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    // Local state:
    const [roleArn, setRoleArn] = useState<string>("");
    const [errorMsg, setErrorMsg] = useState<string | null>(null);
    const [environmentInitialized, setEnvironmentInitialized] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    // Query state:
    const queryClient = useQueryClient();
    const { mutate: mutateInitializeEnvironment, reset: resetInitializeEnvironmentMutation } = useMutation({
        mutationFn: () =>
            initializeEnvironment(
                envShortCode,
                {
                    cloud: "aws",
                    cloudRegion: envRegion!,
                    cloudCredentialsAWS: { roleArn },
                },
                auth0TokenOptions,
            ),
        onError: (error) => {
            setEnvironmentInitialized(false);
            setErrorMsg(getErrorMessage(error));
        },
        onSuccess: () => {
            setEnvironmentInitialized(true);
            queryClient.invalidateQueries({ queryKey: GET_ENVIRONMENT_QUERY_KEY(envShortCode) });
        },
        onSettled: () => {
            setLoading(false);
        },
    });
    const { mutate: mutateProvisionEnvironment, reset: resetProvisionMutation } = useMutation({
        mutationFn: () => provisionEnvironment(envShortCode, auth0TokenOptions),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: GET_ENVIRONMENT_QUERY_KEY(envShortCode) });
        },
    });

    if (!envRegion) {
        return null;
    }

    return (
        <>
            <AWSDetailsForm envShortCode={envShortCode} region={envRegion} />
            <div style={{ textAlign: "left" }}>
                <Form style={{ padding: "24px 0" }}>
                    <label style={{ ...cloudDetailsTitleStyling }}>RoleArn</label>
                    <Form.Input
                        name={"RoleArn"}
                        style={{ width: "100%" }}
                        onChange={(event) => setRoleArn(event.target.value)}
                    />
                </Form>
                {environmentInitialized ? (
                    <Button
                        primary
                        style={{ width: "100%" }}
                        fluid
                        size="large"
                        name="method"
                        type="submit"
                        onClick={() => {
                            resetProvisionMutation();
                            mutateProvisionEnvironment();
                        }}
                    >
                        Create Predibase Environment
                    </Button>
                ) : (
                    <Button
                        disabled={!roleArn}
                        secondary
                        loading={loading}
                        style={{ width: "100%" }}
                        fluid
                        size="large"
                        name="method"
                        type="submit"
                        onClick={() => {
                            setLoading(true);
                            resetInitializeEnvironmentMutation();
                            mutateInitializeEnvironment();
                        }}
                    >
                        Validate Role Authorization
                    </Button>
                )}
                {errorMsg && !environmentInitialized && (
                    <Message negative>
                        <Message.Header>Error</Message.Header>
                        <div>{cleanCloudSetupErrorMessage(errorMsg)}</div>
                    </Message>
                )}
            </div>
            <Divider hidden />
        </>
    );
};

export default AWSSetupView;
