import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Icon, Menu, Tab } from "semantic-ui-react";
import { role } from "../api_generated";
import { tier } from "../api_generated/models/tier";
import { USER_STATE } from "../state/global";
import { SETTINGS_TAB_STATE } from "../state/settings";
import { isKratosUserContext } from "../utils/kratos";
import { useAllRolesForUserUUIDTemporary, useUserRole } from "./query";
import BillingTab from "./tabs/billing/BillingTab";
import MembersTab from "./tabs/members/MembersTab";
import UserProfileTab from "./tabs/profile/UserProfileTab";
import ReservationsTab from "./tabs/reservations/ReservationsTab";
import UsageTab from "./tabs/usage/UsageTab";

const SettingsView = () => {
    // Recoil state:
    const [tabState, setTabState] = useRecoilState(SETTINGS_TAB_STATE);
    const [userContext] = useRecoilState(USER_STATE);

    let subscriptionTier;
    if (userContext) {
        const isKratosContext = isKratosUserContext(userContext);
        subscriptionTier = isKratosContext ? userContext.tenant.subscription.tier : userContext.tenant.tier;
    }

    /*
     * *** STRIPE INTEGRATION ***
     * Stripe - Credit Card Verification Confirmation Route
     * For some credit cards, users will be redirected from Predibase to their
     * bank's website to verify their identity. After they verify their identity,
     * they will be redirected back to Predibase. This route will handle that
     * and give them confirmation that the process was successful.
     */
    const { search } = useLocation();
    const params = useMemo(() => new URLSearchParams(search), [search]);
    const tab = params.get("tab");
    useEffect(() => {
        if (tab === "billing") {
            setTabState(3);
        }
    }, [tab, setTabState]);

    // Get roles for the current user (the "requestor"):
    const requestorRoles = useAllRolesForUserUUIDTemporary(userContext?.uuid);

    const panes = [
        {
            key: "profile",
            menuItem: (
                <Menu.Item style={{ height: "40px" }} key={"profile"}>
                    <Icon name="user" /> My Profile
                </Menu.Item>
            ),
            render: () => (
                <Menu.Item style={{ height: "40px" }} key={"profile"}>
                    <UserProfileTab />
                </Menu.Item>
            ),
        },
        {
            key: "members",
            menuItem: (
                <Menu.Item style={{ height: "40px" }} key={"members"}>
                    <Icon name="group" /> Members
                </Menu.Item>
            ),
            render: () => (
                <Tab.Pane className={"model-tab"}>
                    <MembersTab requestorRoles={requestorRoles} />
                </Tab.Pane>
            ),
        },
        {
            key: "usage",
            menuItem: (
                <Menu.Item style={{ height: "40px" }} key={"usage"}>
                    <Icon name="chart pie" /> Usage
                </Menu.Item>
            ),
            render: () => (
                <Tab.Pane className={"model-tab"}>
                    <UsageTab />
                </Tab.Pane>
            ),
        },
    ];

    const userRole = useUserRole();
    if (userRole === role.ADMIN || userRole === role.SUPPORT) {
        panes.splice(1, 0, { // Insert at index 1
            key: "reservations",
            menuItem: (
                <Menu.Item style={{ height: "40px" }} key={"reservations"}>
                    {/* TODO: What icon? */}
                    <Icon name="calendar" /> Reservations
                </Menu.Item>
            ),
            render: () => (
                <Tab.Pane className={"model-tab"}>
                    <ReservationsTab />
                </Tab.Pane>
            ),
        });
    }

    // Hide the billing tab behind a feature flag (for now) and always for VPC users since we don't charge them for compute:
    // TODO(jeff): hide for enterprise saas too?
    if (subscriptionTier !== tier.ENTERPRISE_VPC) {
        panes.push({
            key: "billing",
            menuItem: (
                <Menu.Item style={{ height: "40px" }} key={"billing"}>
                    <Icon name="credit card" /> Billing
                </Menu.Item>
            ),
            render: () => (
                <Tab.Pane className={"model-tab"}>
                    <BillingTab showConfirmation={tab === "billing"} />
                </Tab.Pane>
            ),
        });
    }

    return (
        <div style={{ padding: "20px" }}>
            <Tab
                panes={panes}
                activeIndex={tabState}
                onTabChange={(_, { activeIndex }) => setTabState(activeIndex as number)}
                menu={{ secondary: true, pointing: true }}
            />
        </div>
    );
};

export default SettingsView;
