import { useEffect, useState } from "react";
import { Message, Tab, TabProps } from "semantic-ui-react";
import {
    adapterVersion,
    finetuningJob,
    finetuningJobStatus,
    predifineMetricsPayload,
    repo,
} from "../../../api_generated";
import { useBaseModelsQuery } from "../../../deployments/data/query";
import ModelConfigViewer from "../../../models/version/ModelConfigViewer";
import { useLegacyModelByIDQuery } from "../../../prompt/query";
import { isTerminalJobStatus } from "../../misc/utils";
import ContinueTrainingForm from "../create/ContinueTrainingForm";
import ParametersForm from "../create/ParametersForm";
import { AdapterConfig } from "../create/schema";
import { ConfigProvider } from "../create/store";
import { adapterTypes } from "../create/utils";
import LossCurves from "./metrics/LearningCurves";
import TurboAccuracyCurves from "./metrics/TurboAccuracyCurves";
import { useHistoricalJobMetricsQuery } from "./metrics/query";
import { useLiveJobMetrics } from "./metrics/websocket";

enum MenuTabEnum {
    Parameters,
    LossCurves,
    LogsPane,
}

const ParametersPaneForm = (props: { adapterVersion: adapterVersion }) => {
    // Parent state:
    const { adapterVersion } = props;

    // Query state:
    // NOTE: If this query fails, it doesn't hurt the user experience
    // (we can still show parameters via the adapterVersion object)
    // so we ignore the error.
    const { data: baseModels } = useBaseModelsQuery({
        refetchOnWindowFocus: false,
    });

    const isContinuedAdapter = adapterVersion.baseVersionTag !== undefined;

    return (
        <>
            {isContinuedAdapter ? (
                <ContinueTrainingForm
                    baseModels={baseModels ?? []}
                    modelDescription={adapterVersion.description ?? ""}
                    setModelDescription={() => {}}
                    readOnly={true}
                    paramsFromJob={adapterVersion.finetuningJob?.params as AdapterConfig | undefined}
                    parentAdapterType={adapterVersion.adapterType as adapterTypes | undefined}
                    parentAndChildDatasetsMatch={undefined} // TODO
                />
            ) : (
                <ParametersForm
                    baseModels={baseModels ?? []}
                    modelDescription={adapterVersion.description ?? ""}
                    setModelDescription={() => {}}
                    readOnly={true}
                    paramsFromJob={adapterVersion.finetuningJob?.params as AdapterConfig | undefined}
                />
            )}
        </>
    );
};

const ParametersPane = (props: { adapterVersion: adapterVersion }) => {
    return (
        <ConfigProvider>
            <ParametersPaneForm adapterVersion={props.adapterVersion} />
        </ConfigProvider>
    );
};

const LegacyConfigPane = (props: { legacyModelId: string }) => {
    const { legacyModelId } = props;

    const { data: legacyModel } = useLegacyModelByIDQuery(legacyModelId, {
        refetchOnWindowFocus: false,
    });

    return (
        <Tab.Pane className={`model-tab`} key={"legacyConfig"}>
            <Message info>
                <Message.Header>Legacy model</Message.Header>
                <p>This model was created with an older version of our fine-tuning stack.</p>
            </Message>
            <ModelConfigViewer config={legacyModel?.config} />
        </Tab.Pane>
    );
};

const LossCurvesPane = (props: {
    adapterVersion: adapterVersion;
    repoUUID: repo["uuid"];
    job: finetuningJob;
    websocketOpen: boolean;
    setWebsocketOpen: React.Dispatch<React.SetStateAction<boolean>>;
    websocketData: predifineMetricsPayload[] | undefined;
    historicalData: predifineMetricsPayload[] | undefined;
    historicalDataIsLoading: boolean;
}) => {
    // Parent state:
    const {
        adapterVersion,
        repoUUID,
        job,
        websocketOpen,
        setWebsocketOpen,
        websocketData,
        historicalData,
        historicalDataIsLoading,
    } = props;
    return (
        <Tab.Pane className={`model-tab`} key={"learningCurves"}>
            <LossCurves
                adapterVersion={adapterVersion}
                repoUUID={repoUUID}
                job={job}
                websocketOpen={websocketOpen}
                setWebsocketOpen={setWebsocketOpen}
                websocketData={websocketData}
                historicalData={historicalData}
                historicalDataIsLoading={historicalDataIsLoading}
            />
        </Tab.Pane>
    );
};

const TurboAccuracyPane = (props: {
    adapterVersion: adapterVersion;
    repoUUID: repo["uuid"];
    job: finetuningJob;
    websocketOpen: boolean;
    setWebsocketOpen: React.Dispatch<React.SetStateAction<boolean>>;
    websocketData: predifineMetricsPayload[] | undefined;
    historicalData: predifineMetricsPayload[] | undefined;
    historicalDataIsLoading: boolean;
}) => {
    // Parent state:
    const {
        adapterVersion,
        repoUUID,
        job,
        websocketOpen,
        setWebsocketOpen,
        websocketData,
        historicalData,
        historicalDataIsLoading,
    } = props;
    return (
        <Tab.Pane className={`model-tab`} key={"turboAccuracy"}>
            <TurboAccuracyCurves
                adapterVersion={adapterVersion}
                repoUUID={repoUUID}
                job={job}
                websocketOpen={websocketOpen}
                setWebsocketOpen={setWebsocketOpen}
                websocketData={websocketData}
                historicalData={historicalData}
                historicalDataIsLoading={historicalDataIsLoading}
            />
        </Tab.Pane>
    );
};

const LogsPane = (props: { errorMessage?: string }) => {
    const { errorMessage } = props;

    return (
        <Tab.Pane className={`model-tab`} key={"logsViewerPane"}>
            <Message negative>
                <Message.Header>Error in model training</Message.Header>
                <div style={{ whiteSpace: "pre-wrap" }}>{errorMessage}</div>
            </Message>
        </Tab.Pane>
    );
};

const AdapterVersionViewSubmenu = (props: {
    adapterVersion: adapterVersion;
    repoUUID: repo["uuid"];
    job: finetuningJob;
}) => {
    // Parent state:
    const { adapterVersion, repoUUID, job } = props;
    const isLegacyAdapter = adapterVersion?.legacyModelId !== undefined;
    // const jobUUID = adapterVersion.finetuningJob?.uuid;
    const jobStatus = adapterVersion.finetuningJob?.status;

    // Local state:
    // By default, show the learning curves pane unless the job is errored - in which case, show the logs pane first:
    const [tabIndex, setTabIndex] = useState(
        jobStatus !== finetuningJobStatus.ERRORED ? MenuTabEnum.LossCurves : MenuTabEnum.LogsPane,
    );

    // Metrics-specific state:
    // ! We place the metrics data queries here because they are expensive and we don't want to reset them when a tab
    // component remounts. Sucks, but it is what it its.
    const [websocketOpen, setWebsocketOpen] = useState<boolean>(false); // extra state to manually close websocket ASAP
    const { data: historicalData, isLoading: historicalDataIsLoading } = useHistoricalJobMetricsQuery(job.uuid, {
        enabled: isTerminalJobStatus(jobStatus),
    });
    const { data: websocketData } = useLiveJobMetrics(job.uuid, repoUUID, adapterVersion.tag, {
        enabled: !isTerminalJobStatus(jobStatus) && websocketOpen,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    });
    // TODO: Lot of cognitive load to managing the training/websocket state like this, so want to improve with a reducer:
    useEffect(() => {
        if (!websocketOpen && jobStatus === finetuningJobStatus.TRAINING) {
            setWebsocketOpen(true);
        }
    }, [jobStatus, websocketOpen]);

    // Create tabs:
    const panes = [];

    if (isLegacyAdapter) {
        panes.push({
            menuItem: "(Legacy) Config",
            // @ts-expect-error this is handled by the if condition above
            render: () => <LegacyConfigPane legacyModelId={adapterVersion.legacyModelId} />,
        });
    } else {
        panes.push({
            menuItem: "Parameters",
            // TODO: Not sure we want to use ace editor for this:
            // TODO: Types are somewhat loose (this component should only accept strings?)
            render: () => <ParametersPane adapterVersion={adapterVersion} />,
        });
    }

    panes.push({
        menuItem: "Loss Curves",
        render: () => (
            <LossCurvesPane
                adapterVersion={adapterVersion}
                repoUUID={repoUUID}
                job={job}
                websocketOpen={websocketOpen}
                setWebsocketOpen={setWebsocketOpen}
                websocketData={websocketData}
                historicalData={historicalData}
                historicalDataIsLoading={historicalDataIsLoading}
            />
        ),
    });

    const adapterType = job?.params?.adapter;
    if (adapterType === "turbo" || adapterType === "turbo_lora") {
        panes.push({
            menuItem: "Turbo Accuracy Curves",
            render: () => (
                <TurboAccuracyPane
                    adapterVersion={adapterVersion}
                    repoUUID={repoUUID}
                    job={job}
                    websocketOpen={websocketOpen}
                    setWebsocketOpen={setWebsocketOpen}
                    websocketData={websocketData}
                    historicalData={historicalData}
                    historicalDataIsLoading={historicalDataIsLoading}
                />
            ),
        });
    }

    if (adapterVersion.status === finetuningJobStatus.ERRORED) {
        panes.push({
            menuItem: "Logs",
            render: () => <LogsPane errorMessage={adapterVersion.finetuningJob?.error} />,
        });
    }

    return (
        <Tab
            style={{ position: "relative" }}
            menu={{ secondary: true, pointing: true }}
            panes={panes}
            activeIndex={tabIndex}
            onTabChange={(e: React.MouseEvent<HTMLDivElement>, { activeIndex }: TabProps) => {
                const idx = Number(activeIndex);
                setTabIndex(idx);
            }}
        />
    );
};

export default AdapterVersionViewSubmenu;
