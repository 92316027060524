import { AxiosResponse } from "axios";
import { getTraceId } from "../../../api/trace";
import { role, roleAssignmentRequest, userList } from "../../../api_generated";
import { Auth0TokenOptions } from "../../../data";
import metrics from "../../../metrics/metrics";
import { GetUsersResponse, Role, TenantMetadata } from "../../../types/user";
import { createV1APIServer, createV2APIServer, redirectIfSessionInvalid } from "../../../utils/api";
import { getErrorMessage } from "../../../utils/errors";

// GET operations:
// TODO: Should be autogenerated:
export interface GetUsersParams {
    offset: number;
    limit: number;
}

export const getUsers = async (params: GetUsersParams, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = "users";
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .get(endpoint, { params })
        .then((res: AxiosResponse<GetUsersResponse>) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "GET",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const updateUser = async (userID: string, request: any, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `users/${userID}`;
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .put(endpoint, request, { headers: { "Content-Type": "application/x-www-form-urlencoded" } })
        .then((res: AxiosResponse) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "PUT",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const deleteUser = async (userID: string, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `users/${userID}`;
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .delete(endpoint, { headers: { "Content-Type": "application/x-www-form-urlencoded" } })
        .then((res: AxiosResponse) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "DELETE",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const getRoles = async (auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = "roles";
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .get(endpoint)
        .then((res: AxiosResponse<Role[]>) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "GET",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const getRoleAssignments = async (userIDs: string[], auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = "roles/assignments";
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return (
        apiServer
            // TODO: WHY IS THIS A POST?
            // TODO: Do we need the explicit headers?
            .post(endpoint, { users: userIDs }, { headers: { "Content-Type": "application/x-www-form-urlencoded" } })
            .then((res: AxiosResponse<Record<string, Role[]>>) => {
                return res.data;
            })
            .catch((error) => {
                const errorMsg = getErrorMessage(error) ?? "";
                metrics.captureError("api_error", errorMsg, {
                    method: "POST",
                    endpoint,
                    trace_id: getTraceId(error),
                });
                redirectIfSessionInvalid(errorMsg);
                throw errorMsg;
            })
    );
};

export const grantRole = async (request: any, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = "roles/grant";
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return (
        apiServer
            .post(endpoint, request, { headers: { "Content-Type": "application/x-www-form-urlencoded" } })
            // TODO: This endpoint returns nothing, is there a bettery way to represent that?
            .then((res: AxiosResponse<undefined>) => {
                return res.data;
            })
            .catch((error) => {
                const errorMsg = getErrorMessage(error) ?? "";
                metrics.captureError("api_error", errorMsg, {
                    method: "POST",
                    endpoint,
                    request,
                    trace_id: getTraceId(error),
                });
                redirectIfSessionInvalid(errorMsg);
                throw errorMsg;
            })
    );
};

// TODO: WHY IS THIS A GET??
export const getInviteLink = async (roleID: number, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `users/invite/global/${roleID}`;
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return (
        apiServer
            // TODO: Do we need the explicit headers?
            .get(endpoint, { headers: { "Content-Type": "application/x-www-form-urlencoded" } })
            .then((res: AxiosResponse<{ link: string }>) => {
                return res.data.link;
            })
            .catch((error) => {
                const errorMsg = getErrorMessage(error) ?? "";
                metrics.captureError("api_error", errorMsg, {
                    method: "GET",
                    endpoint,
                    trace_id: getTraceId(error),
                });
                redirectIfSessionInvalid(errorMsg);
                throw errorMsg;
            })
    );
};

export const generateInviteLinkV2 = async (roleName: role, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `/users/invite?role=${roleName.toUpperCase()}`;
    const apiServer = await createV2APIServer(auth0TokenOptions);

    return apiServer
        .post(endpoint)
        .then((res: AxiosResponse<{ link: string }>) => {
            return res.data.link;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "GET",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const validateInviteToken = async (inviteToken: string, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = "users/invite/validate";
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .post(endpoint, { token: inviteToken }, { headers: { "Content-Type": "application/x-www-form-urlencoded" } })
        .then((res: AxiosResponse<TenantMetadata>) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "POST",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const getUsersV2 = async (params: GetUsersParams, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = "users";
    const apiServer = await createV2APIServer(auth0TokenOptions);

    return apiServer
        .get(endpoint, { params })
        .then((res: AxiosResponse<userList>) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "GET",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const grantRoleV2 = async (
    uuid: string,
    request: roleAssignmentRequest,
    auth0TokenOptions?: Auth0TokenOptions,
) => {
    const endpoint = `/users/${uuid}/roles`;
    const apiServer = await createV2APIServer(auth0TokenOptions);

    return apiServer
        .post(endpoint, request)
        .then((res: AxiosResponse<undefined>) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "POST",
                endpoint,
                request,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};
