import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { Button, Form, FormGroup, Header, Message, MessageHeader, Modal, ModalContent } from "semantic-ui-react";
import { role } from "../../../api_generated";
import { useAuth0TokenOptions } from "../../../data";
import { track } from "../../../metrics/june";
import metrics from "../../../metrics/metrics";
import { USER_STATE } from "../../../state/global";
import { getErrorMessage } from "../../../utils/errors";
import { useUserRole } from "../../query";
import { generateInviteLinkV2 } from "./data";
import { generateUserRoleDropdownOptions } from "./util";

type InviteUserModalProps = {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const InviteUserModalV2 = ({ open, setOpen }: InviteUserModalProps) => {
    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    // User state:
    const [userContext] = useRecoilState(USER_STATE);
    const userRole = useUserRole();

    // Local state:
    const [copied, setCopied] = useState(false);
    const [selectedRole, setSelectedRole] = useState<role>(role.USER);

    const {
        mutate: generateLink,
        isError: linkIsErrored,
        error: generateLinkError,
        isPending: linkIsPending,
        reset: resetMutation,
        data: link,
    } = useMutation({
        mutationFn: (selectedRole: role) => generateInviteLinkV2(selectedRole, auth0TokenOptions),
    });

    // Run on initial pageload to generate first invite link:
    useEffect(() => {
        if (userRole?.toLocaleUpperCase() === role.READONLY) {
            return;
        }
        generateLink(selectedRole);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const inviteLink = link ?? "";

    return (
        <Modal
            size={"small"}
            open={open}
            onClose={() => {
                setOpen(false);

                // Reset state:
                setCopied(false);
                resetMutation();
            }}
        >
            <ModalContent style={{ padding: "30px" }}>
                <div style={{ marginBottom: "20px" }}>
                    <Header as={"h2"} size="medium" style={{ paddingTop: "10px", display: "inline" }}>
                        Invite Team Members
                    </Header>
                    <FontAwesomeIcon
                        style={{ float: "right", cursor: "pointer" }}
                        icon="x"
                        onClick={() => setOpen(false)}
                    />
                </div>
                {linkIsErrored ? (
                    <Message negative>
                        <MessageHeader>Error with Invite Flow</MessageHeader>
                        <p>{getErrorMessage(generateLinkError)}</p>
                    </Message>
                ) : (
                    <div>
                        <div style={{ marginBottom: "20px" }}>
                            Invite others to join your team, {userContext?.tenant.name}. All <b>users</b> in your team
                            share models, datasets, and engines.
                        </div>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "end",
                                justifyContent: "space-between",
                                gap: "10px",
                            }}
                        >
                            <div style={{ width: "100%" }}>
                                <Form>
                                    <FormGroup>
                                        <Form.Input
                                            width={11}
                                            readOnly={!inviteLink}
                                            fluid
                                            label="Invite Link"
                                            value={inviteLink}
                                            loading={linkIsPending}
                                            placeholder="Generating link..."
                                        />
                                        <Form.Select
                                            fluid
                                            width={5}
                                            label="Role"
                                            options={generateUserRoleDropdownOptions(userRole)}
                                            value={selectedRole}
                                            onChange={(e, { value: roleName }) => {
                                                setSelectedRole(roleName as role);
                                                resetMutation();
                                                generateLink(roleName as role);
                                            }}
                                            placeholder="Role"
                                        />
                                    </FormGroup>
                                </Form>
                            </div>
                            <div>
                                {copied ? (
                                    <Button
                                        style={{ width: "112px", marginBottom: "1em" }}
                                        onClick={() => {
                                            navigator.clipboard.writeText(String(inviteLink));
                                            setCopied(true);
                                        }}
                                        color={"green"}
                                    >
                                        Copied!
                                    </Button>
                                ) : (
                                    <Button
                                        style={{ width: "112px", marginBottom: "1em" }}
                                        onClick={() => {
                                            // Purposely updated to match PostHog's suggested naming convention
                                            metrics.capture("team_member_invited");
                                            userContext && track(userContext, "invite_team_members");
                                            navigator.clipboard.writeText(String(inviteLink));
                                            setCopied(true);
                                        }}
                                        primary
                                    >
                                        Copy
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </ModalContent>
        </Modal>
    );
};

export default InviteUserModalV2;
