import { useMutation, useQueryClient } from "@tanstack/react-query";
import { upperFirst } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import { Button, Divider, DropdownItemProps, Grid, Icon, Message, Modal } from "semantic-ui-react";
import {
    acceleratorId,
    baseModel,
    createDeploymentRequest,
    deployment,
    deploymentAcceleratorOption,
    deploymentQuantization,
    tier,
    updateDeploymentRequest,
} from "../../../api_generated";
import { DropdownField, IntegerInputField, StringInputField } from "../../../components/modal-utils";
import { useAuth0TokenOptions } from "../../../data";
import { GET_DEPLOYMENTS_QUERY_KEY } from "../../../query";
import { USER_STATE } from "../../../state/global";
import { SEMANTIC_GREY } from "../../../utils/colors";
import { getErrorMessage } from "../../../utils/errors";
import { isKratosUserContext } from "../../../utils/kratos";
import { centsToDollars } from "../../../utils/strings";
import { createDeployment, updateDeployment } from "../../data/data";
import { useBaseModelsQuery, useReservationsQuery } from "../../data/query";
import {
    generateAcceleratorSelectorOptions,
    generateBaseModelSelectorOptions,
    isEnterpriseTierModel,
    sortQuantizationTypes,
} from "../../misc/dropdown-utils";

import "./CreateUpdateDeploymentModal.css";

const formatCooldownTime = (cooldownTime?: number, minReplicas?: number, maxReplicas?: number) => {
    if (cooldownTime === undefined || minReplicas === undefined || maxReplicas === undefined) {
        return <></>;
    }
    // If they are both 0, then we should return nothing
    if (minReplicas === 0 && maxReplicas === 0) {
        return <></>;
    }
    if (minReplicas === maxReplicas) {
        return (
            <>
                Deployment is configured to <b>be always on.</b>
            </>
        );
    }
    if (cooldownTime < 60) {
        return (
            <>
                Scales between{" "}
                <b>
                    {minReplicas} to {maxReplicas}
                </b>{" "}
                replicas. Waits at least <b>{cooldownTime} seconds</b> before scaling down extra replicas.
            </>
        );
    }
    if (cooldownTime < 3600) {
        return (
            <>
                Scales between{" "}
                <b>
                    {minReplicas} to {maxReplicas}
                </b>{" "}
                replicas. Waits at least <b>{Math.round(cooldownTime / 60)} minutes</b> before scaling down extra
                replicas.
            </>
        );
    }
    if (cooldownTime < 86400) {
        return (
            <>
                Scales between{" "}
                <b>
                    {minReplicas} to {maxReplicas}
                </b>{" "}
                replicas. Waits at least <b>{Math.round(cooldownTime / 3600)} hour(s)</b> before scaling down extra
                replicas.
            </>
        );
    }
    return (
        <>
            Scales from{" "}
            <b>
                {minReplicas} to {maxReplicas}
            </b>{" "}
            replicas. Waits at least <b>{Math.round(cooldownTime / 86400)} days</b> before scaling down extra replicas.
        </>
    );
};

const CreateUpdateDeploymentModal = (props: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    deployments?: deployment[];
    deployment?: deployment;
}) => {
    const { deployment, deployments } = props;
    // React Query is sending an empty object when the API is still loading, so we need to check for that:
    const isEditMode = !!deployment && Object.getOwnPropertyNames(deployment).length > 0;

    // Recoil state:
    const [userContext] = useRecoilState(USER_STATE);
    // Derived user state:
    let userTier: tier | undefined;
    if (userContext) {
        const isKratosContext = isKratosUserContext(userContext);
        userTier = isKratosContext ? userContext.tenant.subscription.tier : userContext?.tenant.tier;
    }
    const userIsInFreeTier = userTier === tier.FREE;
    const userIsInDevTier = userTier === tier.PREMIUM;
    const userIsInEnterpriseTier = userTier && [tier.ENTERPRISE_SAAS, tier.ENTERPRISE_VPC].includes(userTier);
    const numDedicatedDeployments = deployments?.length;

    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    // Local state:
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    // Defaults
    const acceleratorDefault = "";
    const quantizationDefault = "";
    const autoSuspendSecsDefault = "3600";
    const minReplicasDefault = "0";
    const maxReplicasDefault = "1";
    const targetPendingRequestsDefault = "1";

    // Required variables:
    const [deploymentName, setDeploymentName] = useState<string>("");
    const [baseModel, setBaseModel] = useState<string>("");
    const [accelerator, setAccelerator] = useState<string>(acceleratorDefault);
    const [quantization, setQuantization] = useState<string>(quantizationDefault);
    const [autoSuspendSecs, setAutoSuspendSecs] = useState<string>(autoSuspendSecsDefault);
    const [minReplicas, setMinReplicas] = useState<string>(minReplicasDefault);
    const [maxReplicas, setMaxReplicas] = useState<string>(maxReplicasDefault);
    const [targetPendingRequests, setTargetPendingRequests] = useState<string>(targetPendingRequestsDefault);

    // Query state:
    const queryClient = useQueryClient();
    const { data: baseModels } = useBaseModelsQuery({
        refetchOnWindowFocus: false,
    });

    // TODO NOAH get the accelerator data from the normal place? Add it in the go code to return "reservation required"
    const { data: reservations } = useReservationsQuery({
        refetchOnWindowFocus: false,
    });

    /**
     * Mutations
     */
    const {
        mutate: mutateCreateDeployment,
        reset: resetCreateDeploymentMutation,
        isPending: createMutationPending,
    } = useMutation({
        mutationFn: (config: createDeploymentRequest) => createDeployment(config, auth0TokenOptions),
        onSuccess: () => {
            props.setOpen(false);
            queryClient.invalidateQueries({ queryKey: GET_DEPLOYMENTS_QUERY_KEY });
        },
        onError: (error) => {
            setErrorMessage(getErrorMessage(error));
        },
    });

    const {
        mutate: mutateUpdateDeployment,
        reset: resetUpdateDeploymentMutation,
        isPending: resetMutationPending,
    } = useMutation({
        mutationFn: (request: updateDeploymentRequest) =>
            updateDeployment(deployment?.uuid ?? "", request, auth0TokenOptions),
        onSuccess: () => {
            props.setOpen(false);
            queryClient.invalidateQueries({ queryKey: GET_DEPLOYMENTS_QUERY_KEY });
        },
        onError: (error) => {
            setErrorMessage(typeof error === "string" ? error : getErrorMessage(error as any));
        },
    });

    /**
     * Static Transformed Data
     */
    // Variables that should only be generated once (after new data is fetched):
    const { baseModelSelectorOptions, baseModelLookup } = useMemo(() => {
        const [baseModelSelectorOptions, baseModelLookup] = generateBaseModelSelectorOptions(baseModels, userTier);
        return { baseModelSelectorOptions, baseModelLookup };
    }, [baseModels, userTier]);

    /**
     * Reactive Data (based on user selections)
     */
    // Update selector options based on the base model:
    const { acceleratorSelectorOptions, acceleratorLookup, acceleratorsQuantizationMap } = useMemo(() => {
        const baseModelObj = baseModelLookup[baseModel] as baseModel | undefined;
        // TODO: Probably need to rewrite this
        let accelerators: deploymentAcceleratorOption[] = [];
        const acceleratorsMap = new Map<string, deploymentAcceleratorOption>();
        let acceleratorsQuantizationMap: Record<string, deploymentQuantization[]> = {};

        if (baseModelObj?.accelerators?.serving) {
            Object.entries(baseModelObj.accelerators.serving).forEach(([quantizationType, availableAccelerators]) => {
                // Add accelerators to set to remove duplicates:
                availableAccelerators?.forEach((accelerator) => {
                    acceleratorsMap.set(accelerator.id, accelerator);
                });

                // Append to the quantization map:
                for (const accelerator of availableAccelerators) {
                    if (!userTier || !accelerator.availability.tiers.includes(userTier)) {
                        continue;
                    }

                    if (acceleratorsQuantizationMap[accelerator.id]) {
                        acceleratorsQuantizationMap[accelerator.id].push(quantizationType as deploymentQuantization);
                        continue;
                    }
                    acceleratorsQuantizationMap[accelerator.id] = [quantizationType as deploymentQuantization];
                }
            });
        }

        acceleratorsMap.forEach((accelerator) => accelerators.push(accelerator));
        const [acceleratorSelectorOptions, acceleratorLookup] = generateAcceleratorSelectorOptions(
            accelerators,
            userTier,
            reservations,
        );
        return { acceleratorSelectorOptions, acceleratorLookup, acceleratorsQuantizationMap };
    }, [baseModel, baseModelLookup, userTier, reservations]);

    // Update quantization options based on the accelerator:
    const quantizationSelectorOptions = useMemo(() => {
        let quantizationSelectorOptions: DropdownItemProps[] = [];
        if (!accelerator || !acceleratorsQuantizationMap[accelerator]) {
            return quantizationSelectorOptions;
        }

        acceleratorsQuantizationMap[accelerator]?.sort(sortQuantizationTypes)?.forEach((quantization) =>
            quantizationSelectorOptions.push({
                key: quantization,
                text: upperFirst(quantization),
                value: quantization,
            }),
        );

        return quantizationSelectorOptions;
    }, [accelerator, acceleratorsQuantizationMap]);

    // Auto-select the first non-disabled accelerator option after the base model changes:
    useEffect(() => {
        if (!Array.isArray(acceleratorSelectorOptions) || acceleratorSelectorOptions.length === 0) {
            return;
        }

        const newAccelerator = acceleratorSelectorOptions.filter((opt) => !Boolean(opt.disabled))[0]
            ?.value as acceleratorId;
        setAccelerator(newAccelerator);
    }, [baseModel, acceleratorSelectorOptions]);

    // Auto-select the first quantization option after the accelerator changes:
    useEffect(() => {
        if (
            !Array.isArray(acceleratorsQuantizationMap[accelerator]) ||
            acceleratorsQuantizationMap[accelerator].length === 0
        ) {
            return;
        }
        setQuantization(acceleratorsQuantizationMap[accelerator][0]);
    }, [accelerator, acceleratorsQuantizationMap]);

    // Convert local state to actual base model and accelerator objects:
    let selectedBaseModel = baseModelLookup[baseModel] as baseModel | undefined;
    // ! WARNING: "undefined" here is actually the "auto" value -- see dropdown-utils.tsx:
    let selectedAccelerator = acceleratorLookup[accelerator as acceleratorId] as
        | deploymentAcceleratorOption
        | undefined;

    /**
     * Edit Mode
     */
    // Set the initial values based on the deployment:
    useEffect(() => {
        if (deployment === undefined || Object.getOwnPropertyNames(deployment).length === 0) {
            return;
        }

        setDeploymentName(deployment?.name ?? "");
        setBaseModel(deployment?.model?.name ?? "");
        setAccelerator(deployment?.accelerator?.id ?? "");
        setQuantization(deployment?.quantization ?? quantizationDefault);
        setAutoSuspendSecs(String(deployment?.config?.cooldownTime ?? autoSuspendSecsDefault));
        setMinReplicas(String(deployment?.config?.minReplicas ?? minReplicasDefault));
        setMaxReplicas(String(deployment?.config?.maxReplicas ?? maxReplicasDefault));
        setTargetPendingRequests(String(deployment?.config?.scaleUpRequestThreshold ?? targetPendingRequestsDefault));
    }, [deployment]);

    // For custom models, we need to add the current values so the user can successfully edit.
    if (isEditMode && selectedBaseModel === undefined) {
        // Add the current values to the base model list:
        selectedBaseModel = deployment.model;

        const baseModelName = selectedBaseModel?.name ?? "";
        baseModelSelectorOptions.push({
            key: baseModelName,
            text: baseModelName,
            value: baseModelName,
        });

        // Add the current values to the accelerator list:
        selectedAccelerator = {
            ...deployment.accelerator,
            maxBatchPrefillTokens: deployment?.model?.maxBatchPrefillTokens ?? 0,
            maxTotalTokens: deployment?.model?.maxTotalTokens ?? 0,
            maxInputLength: deployment?.model?.maxInputLength ?? 0,
        };
        acceleratorSelectorOptions.push({
            key: selectedAccelerator?.id,
            text: selectedAccelerator?.name,
            value: selectedAccelerator?.id,
        });

        // Add the current values to the quantization list:
        const deploymentQuant = deployment?.quantization;
        if (
            deploymentQuant &&
            quantizationSelectorOptions.indexOf(
                (quantOption: DropdownItemProps) => quantOption.value === deploymentQuant,
            ) === 0
        ) {
            quantizationSelectorOptions.push({
                key: deployment?.quantization,
                text: upperFirst(deployment?.quantization),
                value: deployment?.quantization,
            });
        }
    }

    /**
     * Derived State for warnings, blocking, messages
     */
    const userCanCreateMoreDeployments =
        numDedicatedDeployments !== undefined && userIsInDevTier ? numDedicatedDeployments < 1 : true;
    const userChoseValidBaseModel =
        selectedBaseModel && userIsInDevTier ? !isEnterpriseTierModel(selectedBaseModel) : true;
    const userChoseValidAccelerator = selectedAccelerator
        ? selectedAccelerator?.availability?.tiers?.includes(userTier as unknown as tier) // TODO: Need to update SubscriptionType to generated version
        : false; // Users must choose an accelerator
    const canDeploy =
        deploymentName &&
        selectedBaseModel &&
        (userIsInDevTier || userIsInEnterpriseTier) &&
        userCanCreateMoreDeployments &&
        userChoseValidBaseModel &&
        minReplicas &&
        maxReplicas &&
        (targetPendingRequests || minReplicas === maxReplicas) &&
        (autoSuspendSecs || minReplicas === maxReplicas) &&
        userChoseValidAccelerator;

    return (
        <Modal
            name="createUpdateDeploymentModal"
            onOpen={() => {
                props.setOpen(true);

                // TODO: I'm thinking these should go in `onClose`:
                setErrorMessage(null);
                setDeploymentName("");
                setBaseModel("");
                setAccelerator(acceleratorDefault);
                setQuantization(quantizationDefault);
                setAutoSuspendSecs(autoSuspendSecsDefault);
            }}
            onClose={() => {
                props.setOpen(false);
                resetCreateDeploymentMutation();
                resetUpdateDeploymentMutation();
            }}
            open={props.open}
        >
            <Modal.Header>{isEditMode ? "Update" : "New"} Private Serverless Deployment</Modal.Header>
            <Modal.Content>
                <span>
                    Spin up a private deployment of a pretrained model which is billed at $/gpu-hour rates{" "}
                    {
                        // eslint-disable-next-line react/jsx-no-target-blank
                        <a href="https://predibase.com/pricing" target="_blank" rel="noopener">
                            (See pricing)
                        </a>
                    }
                </span>
                <Divider hidden />

                {userIsInFreeTier && (
                    <Message warning color={"yellow"} className={"disabled-warning"}>
                        <p className={"message-text"}>
                            <Icon name={"warning sign"} />
                            Private serverless deployments are disabled until a credit card is added. Please go to{" "}
                            <b>{"Settings > Billing"}</b> to add your credit card to create a private serverless
                            deployment.
                        </p>
                    </Message>
                )}

                {!userCanCreateMoreDeployments && (
                    <Message warning color={"yellow"} className={"disabled-warning"}>
                        <p className={"message-text"}>
                            <Icon name={"warning sign"} />
                            You are limited to one private serverless deployments in the Developer Tier. Please contact{" "}
                            {
                                // eslint-disable-next-line react/jsx-no-target-blank
                                <a href="mailto:sales@predibase.com" target="_blank" rel="noopener">
                                    sales@predibase.com
                                </a>
                            }{" "}
                            if you'd like to upgrade to the Enterprise Tier.
                        </p>
                    </Message>
                )}
                <Grid>
                    <Grid.Row style={{ paddingBottom: 0, paddingTop: `${8 / 14}rem` }}>
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                            <StringInputField
                                name="deploymentName"
                                placeholder="Name"
                                value={deploymentName}
                                setValue={setDeploymentName}
                                header="Deployment name"
                                description="Choose an identifier for your deployment (ex. “my-llama-3-8b”)"
                                fullWidth
                                disabled={isEditMode}
                            />
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                            <DropdownField
                                name="baseModel"
                                placeholder="Model"
                                description="Choose the LLM to deploy"
                                value={baseModel}
                                setValue={setBaseModel}
                                header="Base model"
                                options={baseModelSelectorOptions}
                                fullWidth
                                disabled={isEditMode}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{ paddingBottom: 0, paddingTop: 0 }}>
                        <Grid.Column mobile={16} tablet={16} computer={16}>
                            <Divider style={{ marginTop: `-${8 / 14}rem`, marginBottom: `${20 / 14}rem` }} />
                        </Grid.Column>
                    </Grid.Row>
                    {baseModel !== "" && (
                        <>
                            <Grid.Row style={{ paddingBottom: 0, paddingTop: 0 }}>
                                <Grid.Column mobile={16} tablet={8} computer={8}>
                                    <DropdownField
                                        // clearable
                                        name="accelerator"
                                        header="Accelerator (GPU)"
                                        placeholder="Accelerator"
                                        description="Choose the hardware to deploy your model on"
                                        value={accelerator}
                                        setValue={setAccelerator}
                                        options={acceleratorSelectorOptions}
                                        fullWidth
                                        disabled={isEditMode}
                                    />
                                </Grid.Column>
                                <Grid.Column mobile={16} tablet={8} computer={8}>
                                    <DropdownField
                                        // clearable
                                        name="quantization"
                                        header="Quantization"
                                        placeholder="None"
                                        description="Quantization reduces model size, allowing for larger context windows."
                                        value={quantization}
                                        setValue={setQuantization}
                                        options={quantizationSelectorOptions}
                                        fullWidth
                                        disabled={isEditMode}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row style={{ paddingBottom: 0, paddingTop: 0 }}>
                                <Grid.Column mobile={16} tablet={8} computer={8}>
                                    <div style={{ flex: "1" }}>
                                        <IntegerInputField
                                            name="minReplicas"
                                            placeholder="Min Replicas"
                                            header="Min replicas"
                                            description="The minimum number of replicas your deployment will scale down to"
                                            value={minReplicas}
                                            setValue={setMinReplicas}
                                            fullWidth
                                        />
                                        <p
                                            style={{
                                                color: SEMANTIC_GREY,
                                                fontSize: "0.9em",
                                                paddingBottom: `${32 / 14}rem`,
                                                marginTop: "-1rem",
                                            }}
                                        >
                                            Note: If min and max replicas are equal, the deployment will never scale any
                                            replicas down, effectively ignoring the next two fields.
                                        </p>
                                    </div>
                                </Grid.Column>
                                <Grid.Column mobile={16} tablet={8} computer={8}>
                                    <div style={{ flex: "1" }}>
                                        <IntegerInputField
                                            name="maxReplicas"
                                            placeholder="Max Replicas"
                                            header="Max replicas"
                                            description="The maximum number of replicas your deployment will scale up to"
                                            value={maxReplicas}
                                            setValue={setMaxReplicas}
                                            fullWidth
                                        />
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row style={{ paddingTop: 0 }}>
                                <Grid.Column mobile={16} tablet={8} computer={8}>
                                    <IntegerInputField
                                        name="targetPendingRequests"
                                        placeholder="Scale Up Threshold"
                                        header="Scale up threshold (requests)"
                                        description="Defines the pending (or in-progress) requests each active replica must have before scaling up past 1 replica."
                                        value={targetPendingRequests}
                                        setValue={setTargetPendingRequests}
                                        fullWidth
                                    />
                                    <p style={{ color: SEMANTIC_GREY, fontSize: "0.9em", marginTop: "-1rem" }}>
                                        Note: If all replicas have fewer requests than the scale up threshold, extra
                                        replicas scale down after the cooldown time.
                                    </p>
                                </Grid.Column>
                                <Grid.Column mobile={16} tablet={8} computer={8}>
                                    <IntegerInputField
                                        name="autoSuspend"
                                        placeholder="Cooldown"
                                        header="Cooldown time (seconds)"
                                        description="The duration after which your deployment will automatically scale down replicas if it no longer needs them"
                                        value={autoSuspendSecs}
                                        setValue={setAutoSuspendSecs}
                                        fullWidth
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </>
                    )}
                </Grid>
                {errorMessage && <Message negative visible header={"Error"} content={errorMessage} />}
            </Modal.Content>

            {/* // Bottom bar with buttons: */}
            <Modal.Actions>
                <span style={{ fontSize: `${12 / 14}rem`, marginRight: `1rem` }}>
                    {formatCooldownTime(Number(autoSuspendSecs), Number(minReplicas), Number(maxReplicas))}
                </span>
                {selectedAccelerator && !userIsInEnterpriseTier && (
                    <span style={{ fontSize: `1rem`, marginRight: `1rem` }}>
                        Cost: <b>{centsToDollars(selectedAccelerator?.compute?.cost?.centsPerHour)}/hr</b>
                    </span>
                )}
                <Button
                    size={"small"}
                    onClick={() => {
                        props.setOpen(false);
                        resetCreateDeploymentMutation();
                        resetUpdateDeploymentMutation();
                    }}
                >
                    Cancel
                </Button>
                <Button
                    icon
                    color={"green"}
                    labelPosition={"right"}
                    size={"small"}
                    loading={createMutationPending || resetMutationPending}
                    disabled={!canDeploy}
                    onClick={() => {
                        if (isEditMode) {
                            resetUpdateDeploymentMutation();
                            mutateUpdateDeployment({
                                // description: "", TODO: Implement this?
                                config: {
                                    minReplicas: minReplicas ? Number(minReplicas) : undefined,
                                    maxReplicas: maxReplicas ? Number(maxReplicas) : undefined,
                                    cooldownTime: autoSuspendSecs ? Number(autoSuspendSecs) : undefined,
                                    scaleUpRequestThreshold: targetPendingRequests
                                        ? Number(targetPendingRequests)
                                        : undefined,
                                    // hfToken: huggingfaceToken, TODO: Implement this?
                                    customArgs: deployment?.config?.customArgs,
                                    loraxImageTag: deployment?.config?.loraxImageTag,
                                },
                            });

                            return;
                        }

                        resetCreateDeploymentMutation();
                        mutateCreateDeployment({
                            name: deploymentName,
                            // description: "", TODO: Implement this?
                            config: {
                                baseModel: selectedBaseModel!.name,
                                // TODO once we have a check box in the modal for this, we should instead auto-populate that
                                // check box as "selected" whenever an accelerator that requires a reservation is selected.
                                usesGuaranteedCapacity: selectedAccelerator?.reservationRequired === true,
                                accelerator: selectedAccelerator?.id,
                                quantization: quantization
                                    ? (quantization as unknown as deploymentQuantization)
                                    : undefined,
                                minReplicas: minReplicas ? Number(minReplicas) : undefined,
                                maxReplicas: maxReplicas ? Number(maxReplicas) : undefined,
                                cooldownTime: autoSuspendSecs ? Number(autoSuspendSecs) : undefined,
                                scaleUpRequestThreshold: targetPendingRequests
                                    ? Number(targetPendingRequests)
                                    : undefined,
                                // hfToken: huggingfaceToken, TODO: Implement this?
                                // customArgs: customDeploymentArgs, TODO: Implement this?
                            },
                        });
                    }}
                >
                    {isEditMode ? "Update" : "Deploy"}
                    <Icon inverted name="rocket" color={"green"} />
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default CreateUpdateDeploymentModal;
