import objectHash from "object-hash";
import { predifineMetricsPayload } from "../../../../api_generated";

// Metrics are graphed against the checkpoint number:
export const LINE_AXIS_NAME = "checkpoint_number";

// Not everything in the payload is graphed:
// TODO: Streamline and restructure payload data even more:
export type MetricsPayloadData = predifineMetricsPayload["data"];
export type MetricsPayloadDataKey = keyof Omit<MetricsPayloadData, "checkpoint_number">;
export const CHART_METRIC_NAMES: MetricsPayloadDataKey[] = ["train_metrics_loss", "validation_metrics_loss"];
// TODO: Establish enforced naming standard so that we can pick and splice names automatically instead of maintaining
// another data structure for user-friendly names or labels:
export const metricNameMap: { [key: string]: string } = {
    train_metrics_loss: "Train Loss",
    validation_metrics_loss: "Validation Loss",
};

// This is used by the TurboAccuracyCurves component:
export const generateTurboMetricNames = (turboIndex: number): MetricsPayloadDataKey[] => [
    `train_metrics_turbo_${turboIndex}_top1_accuracy` as MetricsPayloadDataKey,
    `validation_metrics_turbo_${turboIndex}_top1_accuracy` as MetricsPayloadDataKey,
];
export const TURBO_METRIC_NAMES = [0, 1, 2].map((index) => generateTurboMetricNames(index));

// TODO: Hack for R12 (See Slack thread):
// TODO: Actually pretty useful function to keep around in general:
export const deduplicateArray = <T>(arr: NonNullable<T>[]): T[] => {
    const tracker: { [hash: string]: true } = {};
    return arr.filter((val) => {
        const hash = objectHash(val);
        if (hash in tracker) {
            return false;
        }
        tracker[hash] = true;
        return true;
    });
};


export const stepCounterText = (
    latestEvent: predifineMetricsPayload | undefined,
    latestCheckpoint: predifineMetricsPayload | undefined,
) => {
    if (latestEvent === undefined) {
        return "Waiting for metrics...";
    }

    // Until we have the first checkpoint, show the number of steps to the first checkpoint:
    if (latestCheckpoint === undefined) {
        return `Waiting for metrics... Step ${latestEvent.meta.steps} / ${latestEvent.meta.steps_per_checkpoint} until first checkpoint`;
    }

    // After the first checkpoint, show the number of steps until completion:
    return `Step ${latestEvent.meta.steps} / ${latestEvent.meta.total_steps}`;
};
