import { useMutation } from "@tanstack/react-query";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { Button, Form, Message, Modal } from "semantic-ui-react";
import { useAuth0TokenOptions } from "../../../data";
import metrics from "../../../metrics/metrics";
import { KratosUser } from "../../../types/user";
import { getErrorMessage } from "../../../utils/errors";
import { deleteUser, updateUser } from "./data";

const NestedModalDeleteButton = (props: {
    user: KratosUser;
    loading: boolean;
    setLoading: Dispatch<SetStateAction<boolean>>;
    setErrorMessage: Dispatch<SetStateAction<string | null>>;
    resetLocalState: () => void;
    onSubmit: () => void;
}) => {
    // Parent state:
    const { user, loading, setLoading, resetLocalState, onSubmit, setErrorMessage } = props;

    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    const { mutate: mutateDeleteUser, reset: resetMutation } = useMutation({
        mutationFn: () => deleteUser(user?.id!, auth0TokenOptions),
        onSuccess: () => {
            setLoading(false);
            onSubmit();
            resetLocalState();
        },
        onError: (error) => {
            setLoading(false);
            setErrorMessage(getErrorMessage(error));
        },
    });

    return (
        <Modal
            onClose={() => {
                resetMutation();
            }}
            className={metrics.BLOCK_AUTO_CAPTURE}
            trigger={
                <Button
                    className={metrics.BLOCK_AUTO_CAPTURE}
                    content="Delete"
                    labelPosition="right"
                    icon="trash"
                    loading={loading}
                    negative
                />
            }
            header={"Delete " + user.traits.username}
            content="Are you sure?"
            size="mini"
            actions={[
                { key: "cancel", content: "Cancel", onClick: () => resetMutation() },
                {
                    key: "delete",
                    content: "Delete",
                    negative: true,
                    onClick: () => {
                        resetMutation();
                        mutateDeleteUser();
                        setLoading(true);
                    },
                },
            ]}
        />
    );
};

const EditUserModal = (props: {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    user: KratosUser;
    onSubmit: () => void;
}) => {
    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    // Parent state:
    const { open, setOpen, user, onSubmit } = props;

    // Local state:
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [code, setCode] = useState("");

    // Derived state:
    const updateRequest = useMemo(() => {
        const request: any = {
            traits: {
                username: username,
                email: email,
                email_recovery: email,
                name: {
                    first: firstName,
                    last: lastName,
                },
                code: code,
            },
        };
        if (user != null) {
            request.id = user.id;
        }
        return request;
    }, [user, username, email, firstName, lastName, code]);

    const resetLocalState = () => {
        setOpen(false);
        setUsername("");
        setEmail("");
        setFirstName("");
        setLastName("");
        setErrorMessage(null);
    };

    // Query state:
    const { mutate: editUser, reset: resetMutation } = useMutation({
        mutationFn: () => updateUser(user?.id!, updateRequest, auth0TokenOptions),
        onSuccess: () => {
            setLoading(false);
            onSubmit();
            resetLocalState();
        },
        onError: (error) => {
            setErrorMessage(getErrorMessage(error));
        },
    });

    let disabled = username === "";
    return (
        <Modal
            open={open}
            onClose={() => {
                resetLocalState();
                resetMutation();
            }}
            onOpen={() => {
                if (user) {
                    setUsername(user.traits.username);
                    setEmail(user.traits.email);
                    setFirstName(user.traits.name.first);
                    setLastName(user.traits.name.last);
                    setCode(user.traits.code);
                }
                setOpen(true);
            }}
        >
            <Modal.Header>Update User</Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Input
                        autoFocus
                        className={metrics.BLOCK_AUTO_CAPTURE}
                        name="traits.username"
                        label="Username"
                        placeholder="Username"
                        icon="user"
                        iconPosition="left"
                        value={username}
                        onChange={(event) => setUsername(event.target.value)}
                    />
                    <Form.Input
                        className={metrics.BLOCK_AUTO_CAPTURE}
                        name="traits.email"
                        label="Email"
                        placeholder="Email"
                        icon="mail"
                        iconPosition="left"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                    />
                    <Form.Input
                        className={metrics.BLOCK_AUTO_CAPTURE}
                        name="traits.name.first"
                        label="First name"
                        placeholder="First name"
                        icon="user"
                        iconPosition="left"
                        value={firstName}
                        onChange={(event) => setFirstName(event.target.value)}
                    />
                    <Form.Input
                        className={metrics.BLOCK_AUTO_CAPTURE}
                        name="traits.name.last"
                        label="Last name"
                        placeholder="Last name"
                        icon="user"
                        iconPosition="left"
                        value={lastName}
                        onChange={(event) => setLastName(event.target.value)}
                    />
                </Form>
                {errorMessage ? (
                    <Message negative>
                        <Message.Header>Error in user request</Message.Header>
                        <p>{errorMessage}</p>
                    </Message>
                ) : null}
            </Modal.Content>
            <Modal.Actions>
                <NestedModalDeleteButton
                    user={user}
                    loading={loading}
                    setLoading={setLoading}
                    resetLocalState={resetLocalState}
                    onSubmit={onSubmit}
                    setErrorMessage={setErrorMessage}
                />
                <Button
                    className={metrics.BLOCK_AUTO_CAPTURE}
                    onClick={() => {
                        setOpen(false);
                        resetMutation();
                    }}
                >
                    Cancel
                </Button>
                <Button
                    className={metrics.BLOCK_AUTO_CAPTURE}
                    content="Update"
                    labelPosition="right"
                    icon="checkmark"
                    onClick={() => {
                        resetMutation();
                        editUser(updateRequest);
                        setLoading(true);
                    }}
                    loading={loading}
                    disabled={disabled}
                    positive
                />
            </Modal.Actions>
        </Modal>
    );
};

export default EditUserModal;
