import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useContext, useEffect, useState } from "react";
import { Link, useMatch, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
    Breadcrumb,
    Button,
    Checkbox,
    Divider,
    Header,
    Icon,
    Label,
    Loader,
    Menu,
    Message,
    Modal,
    Tab,
    TabProps,
    Table,
} from "semantic-ui-react";
import { BadLink } from "../../../components/BadLink/BadLink";
import EditDiv from "../../../components/EditDiv";
import { SubscriptionButton } from "../../../components/GlobalHeader/SubscriptionButtons";
import LiveTimeAgo from "../../../components/LiveTimeAgo";
import { useAuth0TokenOptions } from "../../../data";
import { track } from "../../../metrics/june";
import metrics from "../../../metrics/metrics";
import { USER_STATE } from "../../../state/global";
import { SEMANTIC_BLACK, SEMANTIC_DARK_YELLOW, SEMANTIC_GREY } from "../../../utils/colors";
import { getErrorMessage } from "../../../utils/errors";
import { FeatureFlagsContext } from "../../../utils/feature-flags";
import { getComputeSubscriptionStatus } from "../../../utils/subscription";
import { deleteModelRepo } from "../../data";
import UploadModelModal from "../../listrepos/UploadModelModal";
import { GET_MODEL_REPO_QUERY_KEY, useModelRepoWithVersionsQuery } from "../../query";
import { ModelContributors, getLatestModel, isTerminalStatus } from "../../util";
import ModelRepoAndVersionLineage from "../ModelRepoAndVersionLineage";
import ModelVersionsTable from "../ModelVersionsTable";
import ModelRepoPerformanceGraph from "./ModelRepoPerformanceGraph/ModelRepoPerformanceGraph";

import { isKratosUserContext } from "../../../utils/kratos";
import "./ModelRepoView.css";

enum SubmenuTab {
    ModelVersions,
    ModelLineage,
    Settings,
}

const ModelRepoView = () => {
    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    // User state:
    const [userContext] = useRecoilState(USER_STATE);
    let subscriptionTier;
    if (userContext) {
        const isKratosContext = isKratosUserContext(userContext);
        subscriptionTier = isKratosContext ? userContext.tenant.subscription.tier : userContext?.tenant.tier;
    }

    // Context state:
    const { featureFlags } = useContext(FeatureFlagsContext);

    // Route state:
    const navigate = useNavigate();
    const repoMatch = useMatch("/models/repo/:id");
    const comparisonMatch = useMatch("/models/repo/:id/diff/:leftModelID/:rightModelID");
    const modelRepoID = repoMatch?.params.id ? parseInt(repoMatch.params.id) : undefined;
    const leftModelID = comparisonMatch?.params.leftModelID ? parseInt(comparisonMatch.params.leftModelID) : undefined;
    const rightModelID = comparisonMatch?.params.rightModelID
        ? parseInt(comparisonMatch.params.rightModelID)
        : undefined;

    // Local state:
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [tabIndex, setTabIndex] = useState(SubmenuTab.ModelVersions);
    const [modelRepo, setModelRepo] = useState<ModelRepo>();
    const [schema] = useState();
    const [latestModel, setLatestModel] = useState<Model | null>(null);
    const [loading, setLoading] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [archivedHidden, setArchivedHidden] = useState(true);

    // Derived state:
    const modelsInRepo = modelRepo?.models;

    // Update tab index if doing a comparison match:
    useEffect(() => {
        if (leftModelID && rightModelID) {
            setTabIndex(SubmenuTab.ModelLineage);
        }
    }, [leftModelID, rightModelID]);

    // When the repo updates, keep track of the latest model:
    useEffect(() => {
        if (modelsInRepo) {
            setLatestModel((_) => getLatestModel(modelsInRepo));
        }
    }, [modelsInRepo]);

    // Query state:
    const queryClient = useQueryClient();
    const { data: modelRepoWithVersions, error: modelRepoWithVersionsError } = useModelRepoWithVersionsQuery(
        modelRepoID!,
        {
            enabled: modelsInRepo?.some((modelVersion) => !isTerminalStatus(modelVersion.status)),
            refetchInterval: 2 * 1000, // 2 seconds
        },
    );
    useEffect(() => {
        if (modelRepoWithVersions) {
            setModelRepo(modelRepoWithVersions.modelRepo);
        }
    }, [modelRepoWithVersions]);
    useEffect(() => {
        if (modelRepoWithVersionsError) {
            setErrorMessage(getErrorMessage(modelRepoWithVersionsError));
        }
    }, [modelRepoWithVersionsError]);

    const { mutate: deleteRepo } = useMutation({
        mutationFn: () => deleteModelRepo(modelRepoID!, auth0TokenOptions),
        onSuccess: () => {
            setDeleted(true);
        },
        onError: (error) => {
            setErrorMessage(getErrorMessage(error));
        },
    });

    if (deleted) {
        navigate("/models");
    }

    if (errorMessage && !modelRepo) {
        if (!modelRepo) {
            return <BadLink authenticated={true} />;
        }
    }
    if (!modelRepo) {
        return <Loader />;
    }

    let errorView = null;
    if (errorMessage) {
        errorView = (
            <Message negative>
                <Message.Header>Error in model training</Message.Header>
                <p>{errorMessage}</p>
            </Message>
        );
    }

    let body = null;
    if (!modelRepo) {
        return <Loader />;
    }

    const modelVersions = modelRepo.models || [];
    const archivedModels = modelVersions.filter((model) => model.archived);

    const modelVersionsTab = (
        <Tab.Pane className={metrics.BLOCK_AUTO_CAPTURE} key={"modelVersions"} as={"div"}>
            <div style={{ minHeight: "500px" }}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <div>
                        <Link
                            to={
                                latestModel
                                    ? `/models/edit/train/version/${latestModel.id}`
                                    : `/models/edit/train/repo/${modelRepo.id}`
                            }
                        >
                            <Button
                                className={metrics.BLOCK_AUTO_CAPTURE}
                                onClick={() => {
                                    metrics.capture("new_version_from_latest", { repoID: modelRepo.id });
                                    userContext && track(userContext, "new_version_from_latest");
                                }}
                                icon="plus"
                                content={latestModel ? "New Model Version from Latest" : "New Model Version"}
                                primary
                                loading={loading}
                            />
                        </Link>
                        <UploadModelModal modelRepo={modelRepo} />
                        &ensp;
                        <Checkbox
                            slider
                            label={
                                <label style={{ color: SEMANTIC_BLACK }}>
                                    Hide Archived Models{" "}
                                    <span style={{ color: SEMANTIC_GREY }}>({archivedModels.length})</span>
                                </label>
                            }
                            checked={archivedHidden}
                            onClick={(event, data) => setArchivedHidden(Boolean(data.checked))}
                        ></Checkbox>
                    </div>
                    {modelVersions && modelVersions.length > 0 ? (
                        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                            <Link
                                to={`/models/repo/${modelRepo.id}/compare`}
                                className={"black-link"}
                                onClick={() => {
                                    metrics.capture("model_compare", { repoID: modelRepo.id });
                                    userContext && track(userContext, "model_compare");
                                }}
                            >
                                <b>
                                    Compare&ensp;
                                    <FontAwesomeIcon icon={"angle-right"} />
                                </b>
                            </Link>
                        </div>
                    ) : null}
                </div>
                {modelRepo.defaultRunMetricName && (
                    <ModelRepoPerformanceGraph
                        modelVersions={
                            archivedHidden ? modelVersions.filter((x) => x.archived === false) : modelVersions
                        }
                        defaultRepoMetric={modelRepo.defaultRunMetricName}
                    />
                )}
                <ModelVersionsTable
                    modelVersions={archivedHidden ? modelVersions.filter((x) => x.archived === false) : modelVersions}
                    repo={modelRepo}
                    setLoading={setLoading}
                    setErrorMessage={setErrorMessage}
                />
            </div>
        </Tab.Pane>
    );

    const modelLineageTab = (
        <Tab.Pane className={metrics.BLOCK_AUTO_CAPTURE} key={"modelLineage"} as={"div"}>
            <ModelRepoAndVersionLineage
                modelRepo={modelRepo}
                schema={schema}
                leftModelID={leftModelID}
                rightModelID={rightModelID}
            />
        </Tab.Pane>
    );

    const settingsTab = (
        <Tab.Pane className={metrics.BLOCK_AUTO_CAPTURE} key={"settings"} as={"div"}>
            <Header as={"h2"} size={"small"}>
                Overview
            </Header>
            <Table basic={"very"} collapsing compact>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell style={{ borderTop: "none", color: SEMANTIC_GREY, paddingRight: "40px" }}>
                            Name
                        </Table.Cell>
                        <EditDiv
                            ogText={modelRepo.modelName}
                            emptyLabel={<span style={{ color: SEMANTIC_GREY }}>(no description)</span>}
                            fitted
                            inputMinWidth={`${500 / 14}rem`}
                            placeholder={"(No description)"}
                            textToRequest={(name: string) => ({ id: modelRepo.id, modelName: name })}
                            endpoint={"/models/repo/" + modelRepo.id}
                            callback={() =>
                                queryClient.invalidateQueries({ queryKey: GET_MODEL_REPO_QUERY_KEY(modelRepo.id) })
                            }
                            asTableCell
                            tableStyle={{ borderTop: "none", paddingRight: "40px" }}
                        />
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell
                            style={{
                                borderTop: "none",
                                color: SEMANTIC_GREY,
                                paddingRight: "100px",
                            }}
                        >
                            Description
                        </Table.Cell>
                        <EditDiv
                            ogText={modelRepo.description}
                            emptyLabel={<span style={{ color: SEMANTIC_GREY }}>(no description)</span>}
                            fitted
                            inputMinWidth={`${500 / 14}rem`}
                            placeholder={"(No description)"}
                            textToRequest={(description: string) => ({ id: modelRepo.id, description: description })}
                            endpoint={"/models/repo/" + modelRepo.id}
                            callback={() =>
                                queryClient.invalidateQueries({ queryKey: GET_MODEL_REPO_QUERY_KEY(modelRepo.id) })
                            }
                            asTableCell
                            tableStyle={{ borderTop: "none", paddingRight: "40px" }}
                        />
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell
                            style={{
                                borderTop: "none",
                                color: SEMANTIC_GREY,
                                paddingRight: "100px",
                            }}
                        >
                            Created
                        </Table.Cell>
                        <Table.Cell style={{ borderTop: "none" }}>
                            <LiveTimeAgo fromDate={new Date(modelRepo.created)} />
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell style={{ borderTop: "none", color: SEMANTIC_GREY, paddingRight: "100px" }}>
                            Last Modified
                        </Table.Cell>
                        <Table.Cell style={{ borderTop: "none" }}>
                            <LiveTimeAgo fromDate={new Date(modelRepo.updated)} />
                            {modelRepo.models?.length != 0 ? (
                                <>
                                    &nbsp;
                                    <span style={{ color: SEMANTIC_GREY }}>({latestModel?.user?.username})</span>
                                </>
                            ) : null}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell style={{ borderTop: "none", color: SEMANTIC_GREY, paddingRight: "100px" }}>
                            Creator
                        </Table.Cell>
                        <Table.Cell style={{ borderTop: "none" }}>{modelRepo.user?.username}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell style={{ borderTop: "none", color: SEMANTIC_GREY, paddingRight: "100px" }}>
                            Contributors
                        </Table.Cell>
                        <Table.Cell style={{ borderTop: "none" }}>{ModelContributors(modelVersions)}</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
            <Divider />
            <Header as={"h2"} size={"small"} style={{ marginBottom: 0 }}>
                Delete Repository
            </Header>
            <span style={{ color: SEMANTIC_GREY, paddingBottom: "10px", display: "block", fontSize: "0.9em" }}>
                Removes this model repository for all users. This action is final and cannot be undone.
            </span>
            <Modal
                className={metrics.BLOCK_AUTO_CAPTURE}
                trigger={<Button icon="trash" content="Delete Model Repository" size="tiny" negative />}
                header={"Delete model repository"}
                content={
                    <Modal.Content>
                        <span>
                            Are you sure you'd like to delete model repository <b>{modelRepo.modelName}</b>? This will
                            cancel any models in progress.
                        </span>
                    </Modal.Content>
                }
                size="mini"
                actions={[
                    { key: "cancel", content: "Cancel" },
                    {
                        key: "delete",
                        content: "Delete",
                        negative: true,
                        onClick: () => (modelRepo ? deleteRepo() : {}),
                    },
                ]}
            />
        </Tab.Pane>
    );
    const panes = [
        {
            key: "modelVersions",
            menuItem: (
                <Menu.Item style={{ height: "40px" }} key={"modelVersions"}>
                    <FontAwesomeIcon
                        icon="circle-nodes"
                        style={{ margin: "0 .35714286em 0 0", opacity: 0.9, float: "none" }}
                    />
                    Model Versions
                    <Label
                        circular
                        horizontal
                        size={"tiny"}
                        style={{ background: "#e0e1e2 none", color: SEMANTIC_GREY, fontWeight: "bold" }}
                    >
                        {modelVersions.length}
                    </Label>
                </Menu.Item>
            ),
            render: () => modelVersionsTab,
        },
        {
            key: "modelLineage",
            menuItem: (
                <Menu.Item style={{ height: "40px" }} key={"modelLineage"}>
                    <Icon name="fork" /> Model Lineage
                </Menu.Item>
            ),
            render: () => modelLineageTab,
        },
        {
            key: "settings",
            menuItem: (
                <Menu.Item style={{ height: "40px" }} key={"settings"}>
                    <Icon name="settings" /> Settings
                </Menu.Item>
            ),
            render: () => settingsTab,
        },
    ];

    let Breadcrumbs = (
        <Breadcrumb>
            <Breadcrumb.Section>
                <Link
                    to="/models"
                    className={metrics.BLOCK_AUTO_CAPTURE}
                    onClick={() => metrics.capture("Model.Navigate", { method: "breadcrumb" })}
                >
                    Models
                </Link>
            </Breadcrumb.Section>
            <Breadcrumb.Divider />
            <Breadcrumb.Section active className={"no-bold"}>
                <Icon name={"folder open outline"} />
                {modelRepo.modelName}
            </Breadcrumb.Section>
        </Breadcrumb>
    );

    const onTabChange = (e: React.MouseEvent<HTMLDivElement>, { activeIndex }: TabProps) => {
        const idx = Number(activeIndex);
        setTabIndex(idx);
    };

    body = (
        <div>
            <div className={"repo-header"}>
                {Breadcrumbs}
                <SubscriptionButton isExpired={userContext?.isExpired} currentTier={subscriptionTier} />
            </div>
            <Divider hidden />
            {getComputeSubscriptionStatus(userContext?.isComputeLimited, featureFlags["Free Trial Testing"]) && (
                <Message warning>
                    <p style={{ color: SEMANTIC_DARK_YELLOW }}>
                        <Icon name="exclamation triangle" />
                        Due to compute restrictions during the free trial, you may experience longer-than-normal queue
                        times.
                    </p>
                </Message>
            )}
            <div className="row">
                <Header as={"h2"} className={"header"} content={modelRepo.modelName} />
                <span style={{ color: SEMANTIC_GREY }}>{modelRepo.description}</span>
                <div style={{ marginTop: "10px", fontSize: "0.9em" }}>
                    Last modified: <LiveTimeAgo fromDate={modelRepo.updated} />
                    {modelRepo.models?.length !== 0 ? (
                        <>
                            &nbsp;
                            <span style={{ color: SEMANTIC_GREY }}>({latestModel?.user?.username})</span>
                        </>
                    ) : null}
                </div>
            </div>
            <br />
            <Tab
                panes={panes}
                activeIndex={tabIndex}
                onTabChange={onTabChange}
                renderActiveOnly={true}
                menu={{ secondary: true, pointing: true }}
            />
        </div>
    );

    return (
        <div style={{ padding: "20px" }}>
            {body}
            <Divider hidden />
            <div className="row">{errorView}</div>
        </div>
    );
};

export default ModelRepoView;
