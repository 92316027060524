import { SemanticCOLORS } from "semantic-ui-react";
import { snakeToTitle } from "../utils/config";

export function connectionDisplayTitle(connectionName: string) {
    if (connectionName === "file_uploads" || connectionName === "public_datasets") {
        return snakeToTitle(connectionName);
    }
    return connectionName;
}

export const dataStatusProps: Record<string, Record<string, SemanticCOLORS>> = {
    connecting: {
        color: "olive",
    },
    connected: {
        color: "green",
    },
    errored: {
        color: "red",
    },
    refreshing: {
        color: "yellow",
    },
};

export const getDataStatusColor = (status: string) => {
    return dataStatusProps[status]?.color || "grey";
};
