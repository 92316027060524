import React from "react";
import { Form } from "semantic-ui-react";
import { SEMANTIC_GREY_ACTIVE } from "../../../utils/colors";

const taxIDOptions = [
    {
        flag: "ad",
        value: "ad_nrt",
        text: "ad_nrt",
    },
    {
        flag: "ae",
        value: "ae_trn",
        text: "ae_trn",
    },
    {
        flag: "ar",
        value: "ar_cuit",
        text: "ar_cuit",
    },
    {
        flag: "au",
        value: "au_abn",
        text: "au_abn",
    },
    {
        flag: "au",
        value: "au_arn",
        text: "au_arn",
    },
    {
        flag: "bg",
        value: "bg_uic",
        text: "bg_uic",
    },
    {
        flag: "bo",
        value: "bo_tin",
        text: "bo_tin",
    },
    {
        flag: "br",
        value: "br_cnpj",
        text: "br_cnpj",
    },
    {
        flag: "br",
        value: "br_cpf",
        text: "br_cpf",
    },
    {
        flag: "ca",
        value: "ca_bn",
        text: "ca_bn",
    },
    {
        flag: "ca",
        value: "ca_gst_hst",
        text: "ca_gst_hst",
    },
    {
        flag: "ca",
        value: "ca_pst_bc",
        text: "ca_pst_bc",
    },
    {
        flag: "ca",
        value: "ca_pst_mb",
        text: "ca_pst_mb",
    },
    {
        flag: "ca",
        value: "ca_pst_sk",
        text: "ca_pst_sk",
    },
    {
        flag: "ca",
        value: "ca_qst",
        text: "ca_qst",
    },
    {
        flag: "ch",
        value: "ch_vat",
        text: "ch_vat",
    },
    {
        flag: "cl",
        value: "cl_tin",
        text: "cl_tin",
    },
    {
        flag: "cn",
        value: "cn_tin",
        text: "cn_tin",
    },
    {
        flag: "co",
        value: "co_nit",
        text: "co_nit",
    },
    {
        flag: "cr",
        value: "cr_tin",
        text: "cr_tin",
    },
    {
        flag: "do",
        value: "do_rcn",
        text: "do_rcn",
    },
    {
        flag: "ec",
        value: "ec_ruc",
        text: "ec_ruc",
    },
    {
        flag: "eg",
        value: "eg_tin",
        text: "eg_tin",
    },
    {
        flag: "es",
        value: "es_cif",
        text: "es_cif",
    },
    {
        flag: "eu",
        value: "eu_oss_vat",
        text: "eu_oss_vat",
    },
    {
        flag: "eu",
        value: "eu_vat",
        text: "eu_vat",
    },
    {
        flag: "gb",
        value: "gb_vat",
        text: "gb_vat",
    },
    {
        flag: "ge",
        value: "ge_vat",
        text: "ge_vat",
    },
    {
        flag: "hk",
        value: "hk_br",
        text: "hk_br",
    },
    {
        flag: "hu",
        value: "hu_tin",
        text: "hu_tin",
    },
    {
        flag: "id",
        value: "id_npwp",
        text: "id_npwp",
    },
    {
        flag: "il",
        value: "il_vat",
        text: "il_vat",
    },
    {
        flag: "in",
        value: "in_gst",
        text: "in_gst",
    },
    {
        flag: "is",
        value: "is_vat",
        text: "is_vat",
    },
    {
        flag: "jp",
        value: "jp_cn",
        text: "jp_cn",
    },
    {
        flag: "jp",
        value: "jp_rn",
        text: "jp_rn",
    },
    {
        flag: "jp",
        value: "jp_trn",
        text: "jp_trn",
    },
    {
        flag: "ke",
        value: "ke_pin",
        text: "ke_pin",
    },
    {
        flag: "kr",
        value: "kr_brn",
        text: "kr_brn",
    },
    {
        flag: "li",
        value: "li_uid",
        text: "li_uid",
    },
    {
        flag: "mx",
        value: "mx_rfc",
        text: "mx_rfc",
    },
    {
        flag: "my",
        value: "my_frp",
        text: "my_frp",
    },
    {
        flag: "my",
        value: "my_itn",
        text: "my_itn",
    },
    {
        flag: "my",
        value: "my_sst",
        text: "my_sst",
    },
    {
        flag: "no",
        value: "no_vat",
        text: "no_vat",
    },
    {
        flag: "nz",
        value: "nz_gst",
        text: "nz_gst",
    },
    {
        flag: "pe",
        value: "pe_ruc",
        text: "pe_ruc",
    },
    {
        flag: "ph",
        value: "ph_tin",
        text: "ph_tin",
    },
    {
        flag: "ro",
        value: "ro_tin",
        text: "ro_tin",
    },
    {
        flag: "rs",
        value: "rs_pib",
        text: "rs_pib",
    },
    {
        flag: "ru",
        value: "ru_inn",
        text: "ru_inn",
    },
    {
        flag: "ru",
        value: "ru_kpp",
        text: "ru_kpp",
    },
    {
        flag: "sa",
        value: "sa_vat",
        text: "sa_vat",
    },
    {
        flag: "sg",
        value: "sg_gst",
        text: "sg_gst",
    },
    {
        flag: "sg",
        value: "sg_uen",
        text: "sg_uen",
    },
    {
        flag: "si",
        value: "si_tin",
        text: "si_tin",
    },
    {
        flag: "sv",
        value: "sv_nit",
        text: "sv_nit",
    },
    {
        flag: "th",
        value: "th_vat",
        text: "th_vat",
    },
    {
        flag: "tr",
        value: "tr_tin",
        text: "tr_tin",
    },
    {
        flag: "tw",
        value: "tw_vat",
        text: "tw_vat",
    },
    {
        flag: "ua",
        value: "ua_vat",
        text: "ua_vat",
    },
    {
        flag: "us",
        value: "us_ein",
        text: "us_ein",
    },
    {
        flag: "uy",
        value: "uy_ruc",
        text: "uy_ruc",
    },
    {
        flag: "ve",
        value: "ve_rif",
        text: "ve_rif",
    },
    {
        flag: "vn",
        value: "vn_tin",
        text: "vn_tin",
    },
    {
        flag: "za",
        value: "za_vat",
        text: "za_vat",
    },
];

const TaxIDComponent = (props: {
    taxCountry: string;
    setTaxCountry: React.Dispatch<React.SetStateAction<string>>;
    taxID: string;
    setTaxID: React.Dispatch<React.SetStateAction<string>>;
    taxIdRequired: boolean;
}) => {
    const { taxCountry, setTaxCountry, taxID, setTaxID, taxIdRequired } = props;
    return (
        <fieldset
            style={{
                border: "none",
                marginTop: "1rem",
            }}
        >
            <legend
                style={{
                    display: "flex",
                    fontSize: "1rem",
                    fontWeight: 700,
                }}
            >{`Business Tax ID${taxIdRequired ? "" : " (Optional)"}`}</legend>
            <p
                style={{
                    fontSize: "0.85714rem",
                    color: SEMANTIC_GREY_ACTIVE,
                    textAlign: "left",
                }}
            >
                If you are a business tax registrant, please enter your business tax ID here.
            </p>
            <Form.Group widths="equal">
                <Form.Dropdown
                    label="Country"
                    value={taxCountry}
                    onChange={(_, data) => {
                        setTaxCountry((data.value as string) ?? "");
                    }}
                    options={taxIDOptions}
                    search
                    selection
                />
                <Form.Input
                    label="Tax ID"
                    value={taxID}
                    onChange={(e) => {
                        setTaxID(e.target.value ?? "");
                    }}
                />
            </Form.Group>
        </fieldset>
    );
};

export default TaxIDComponent;
